import React from 'react'

const MedicalReview = ({type}) => {
    return (
        <div className={type === "desktop"? "rounded-lg shadow-front-2 dark:bg-foreground p-6 mb-7 hidden md:block" : "rounded-lg shadow-front-2 dark:bg-foreground p-6 mb-7 block md:hidden"}>
            <h6 className="text-base font-medium text-xl dark:text-gray-100 text-gray-700 mb-5 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-600 mr-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                Medically Reviewed By
            </h6>

            <div className="flex items-center mb-4">
                <img className="w-16 h-auto flex-shrink-0 rounded-lg mr-4" src="img/dr/Dr-Michael-Ferri.webp" alt="" />
                <div>
                    <h4 className="text-gray-700 dark:text-gray-100 text-xl font-medium mb-2">
                        Dr: Michael Ferri
                    </h4>
                    <span><p className="text-gray-700 dark:text-gray-100 text-sm mb-0">Last Updated on: </p><p className="text-sm text-blue-500 mb-0">August 2, 2024</p></span>
                </div>
            </div>
        </div>
    )
}

export default MedicalReview