import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const PayingForTreatment = () => {

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Paying For Treatment</h1>
                <p className="text-white mt-2">Guide to Affording Opioid Addiction Recovery</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    {/* <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h5> */}

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opioid Rehab is dedicated to helping individuals navigate the financial aspect of opioid addiction treatment by providing clear information on payment options. They offer assistance in finding the right program for inpatient rehab, outpatient programs, and detox services, ensuring that treatment is affordable and accessible for those seeking recovery.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Common Payment Options for Addiction Treatment
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">It's crucial to look at all of your financial options while thinking about addiction treatment. The most popular methods for individuals and families to pay for opioid recovery programs are listed below:</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Opioid Rehab offers a comprehensive directory of top-listed rehabs with various payment options, ensuring you find the right program for your financial situation. The directory includes details on accepted insurance plans, financing options, and available financial aid. To get started, use the easy-to-navigate search to filter rehabs by location, treatment type, and payment options. Many rehab centers will work with you to determine your insurance coverage. Contact the rehab centers directly to learn about payment plans, scholarships, or available grants.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Don't let your concern about expenses keep you from getting the assistance you require. If you have access to the correct knowledge and resources, you can afford therapy. Opioid Rehab is committed to putting you in touch with treatment facilities that will accommodate your budget while yet offering excellent care. Regardless of your insurance status—underinsured, uninsured, or insured—we'll work with you to find a way to make recovery feasible. Get started on the road to recovery right now. Allow us to assist you in choosing a course of treatment that meets your needs in terms of care and cost.</p>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5} />}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default PayingForTreatment