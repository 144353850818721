import React from 'react'

const AboutUsHero = () => {
    return (
        <div className="pt-10 container mx-auto pb-10">
            {/* <div className="lg:inline-flex flex flex-col justify-center border border-white rounded-lg bg-white bg-opacity-60 backdrop-filter backdrop-blur-lg py-5 px-5"> */}
            <div className="rounded-lg shadow-fornt-2 dark:bg-foreground py-10 px-10 bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg mb-7">
                <div className="text-xl font-medium mb-4 text-gray-700 dark:text-gray-100">
                    About Us
                </div>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    Welcome to Opioid Rehab, your go-to source for information on the nation's top treatment facilities and recovery initiatives. We are aware that the road to rehabilitation is a very personal and difficult one. We are committed to helping people and the people they care about find the best treatment centers that can meet their specific requirements.
                </p>

                <div className="text-lg font-medium mb-2 text-gray-700 dark:text-gray-100">
                    Our Mission
                </div>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    Our goal is to empower those battling addiction, mental illness, and other difficulties by giving them access to thorough, accurate, and current information on recovery facilities. Since we think that everyone should have access to high-quality care, we want to make finding the best course of action as simple and encouraging as we can.
                </p>

                <div className="text-lg font-medium mb-2 text-gray-700 dark:text-gray-100">
                    What We Offer
                </div>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    Here at Opioid Rehab, we provide a carefully screened list of rehabilitation facilities with a variety of treatment choices, such as detox centers, mental health institutions, inpatient and outpatient programs, and specialized treatments. To assist you in making an educated choice, each listing provides comprehensive details on the center's offerings, treatment methods, and accreditations.
                </p>

                <div className="text-lg font-medium mb-2 text-gray-700 dark:text-gray-100">
                    Why Choose Us
                </div>
                <ul className="mb-5">
                    <li className="mb-2">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: "7px" }}>
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085"></path>
                            </svg>
                            <p><b className='mr-2'>Comprehensive Listings:</b>We offer thorough descriptions of rehab facilities, providing you with all the knowledge you want to select the best establishment.</p>
                        </div>
                    </li>
                    <li className="mb-2">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: "7px" }}>
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085"></path>
                            </svg>
                            <p><b className='mr-2'>Verified Information:</b>To guarantee that you obtain accurate and trustworthy information, our staff examines the qualifications and available treatments of every institution that is mentioned on our website.</p>
                        </div>
                    </li>
                    <li className="mb-2">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: "7px" }}>
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085"></path>
                            </svg>
                            <p><b className='mr-2'>Supportive Resources:</b>In addition to listings, we provide informative articles, success stories, and advice on the process of recovery to assist you or a loved one in finding healing.</p>
                        </div>
                    </li>
                    <li className="mb-2">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                            <svg className="w-3 h-3 flex-shrink-0 mr-2" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginTop: "7px" }}>
                                <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085"></path>
                            </svg>
                            <p><b className='mr-2'>User-Friendly Search:</b>You may search by region, treatment type, or special needs on our user-friendly platform, which makes it easy to locate the perfect fit.</p>
                        </div>
                    </li>
                </ul>

                {/* <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    Opioid Rehab USA offers a range of services for individuals seeking recovery. They provide detox, residential, medication-assisted, virtual, and outpatient treatment. The detox program provides a safe, supervised environment for detoxification, while the residential treatment offers a structured environment for recovery.
                </p>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    Medication-assisted treatment combines FDA-approved medications with counseling and behavioral therapies to reduce cravings and withdrawal symptoms. Virtual treatment allows individuals to receive care from home, while outpatient treatment offers personalized care plans and flexible scheduling. Also, payment assistance programs are available to ensure access to quality care.
                </p> */}

                <div className="text-lg font-medium mb-2 text-gray-700 dark:text-gray-100">
                    Our Commitment
                </div>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    We promise to be by your side every step of the journey. Opioid Rehab offers the tools and support you require to go forward with your recovery, whether you're looking for assistance for yourself or a loved one.
                </p>

                <div className="text-lg font-medium mb-2 text-gray-700 dark:text-gray-100">
                    Get in Touch
                </div>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    Please contact us if you need help browsing our site or if you have any queries. Our staff is here to assist you in navigating the procedure and guarantee that you receive the finest service possible.
                </p>
                <p className="text-gray-500 dark:text-gray-400 text-md mb-4">
                    We can take the first step toward a better, healthier future by working together.
                </p>
            </div>
            {/* </div> */}
        </div>
    )
}

export default AboutUsHero