import React, { useState, useEffect, useRef, useMemo } from 'react'
import axios from 'axios';
import { base64_encode } from '../../func';

import { Link } from 'react-router-dom';

//import businesses_list from "../../../business";

const useIsInViewport = (ref) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );

    useEffect(() => {
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}

// const useIntersection = (element, rootMargin) => {
//     const [isVisible, setState] = useState(false);

//     useEffect(() => {
//         const observer = new IntersectionObserver(
//             ([entry]) => {
//                 setState(entry.isIntersecting);
//             }, { rootMargin }
//         );

//         element.current && observer.observe(element.current);

//         return () => observer.unobserve(element.current);
//     }, []);

//     return isVisible;
// };

const TrendingListing = () => {
    const ref = useRef();
    const [featuredList, setFeaturedListing] = useState([]);

    const [hide, setHide] = useState(false);
    const [loading, setLoading] = useState(true);

    const inViewport = useIsInViewport(ref, '0px'); // Trigger as soon as the element becomes visible
    //const inViewport = useIntersection(ref, '0px'); // Trigger as soon as the element becomes visible
    //const inViewport = useIntersection(ref, '-200px'); // Trigger if 200px is visible from the element

    // useEffect(() => {
    //     fetchFeaturedListing(10);
    // }, []);

    const LazyImage = (imageProps) => {
        const [shouldLoad, setShouldLoad] = useState(false);
        const placeholderRef = useRef(null);

        useEffect(() => {
            if (!shouldLoad && placeholderRef.current) {
                const observer = new IntersectionObserver(([{ intersectionRatio }]) => {
                    if (intersectionRatio > 0) {
                        setShouldLoad(true);
                    }
                });
                observer.observe(placeholderRef.current);
                return () => observer.disconnect();
            }
        }, [shouldLoad, placeholderRef]);

        return (shouldLoad
            ? <img {...imageProps} alt=""/>
            : <div className="img-placeholder" ref={placeholderRef} />
        );
    };

    const fetchFeaturedListing = (limit) => {
        const json = JSON.stringify({ "limit": limit });

        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_featured_listing`, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setFeaturedListing(response.data.featured_listing);
                setLoading(false);
            }
            else {
                setFeaturedListing([]);
                setLoading(false);
                setHide(true);
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        }).finally(() => {
            window["initializeSwiper"]();
        });
    }

    const createLoadingLayout = () => {
        return (
            [...Array(10)].map((u, index) => (
                <div key={index} className="swiper-slide overflow-hidden rounded-t-lg swiper-slide-active aos-init" data-aos="fade-up" role="group">
                    <div className="relative overflow-hidden rounded-t-lg group">
                        <div className='w-full group-hover:scale-105 overflow-hidden transition-all h-80 rounded-t-lg skeleton'></div>
                        <div className="absolute bottom-0 z-10 top-1/2 flex items-start flex-col justify-end left-0 pb-5 pl-5 pt-2 card-linear-gradient w-full">
                            <div className="flex text-white items-center mb-2">
                                <div className="w-64 h-6 skeleton"></div>
                            </div>
                            <div className="w-64 h-6 skeleton mb-4"></div>
                        </div>
                    </div>
                    <div className="flex relative z-10 justify-between p-6 bg-gray-100 dark:bg-foreground rounded-b-lg">
                        <div className="flex">
                            {[...Array(5)].map((u, index) => (
                                <div className="flex" key={index}>
                                    <svg className="w-5 h-auto flex-shrink-0 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                    </svg>
                                </div>
                            ))}
                            <div className="h-6 w-8 mr-2 skeleton"></div>
                            <div className="h-6 w-8 skeleton"></div>
                        </div>
                    </div>
                </div>
            ))
        )
    }

    const trandingListing = () => {
        return featuredList.map(({ listing_id, title, alias, address, images, rating, reviews }, index) => (
            // <div key={index} className="swiper-slide overflow-hidden rounded-t-lg swiper-slide-active aos-init" data-aos="fade-up" role="group" aria-label={`${index} / ${4}`} style={{ width: '385.333px', marginRight: '30px' }}></div>
            <div key={index} className="swiper-slide overflow-hidden rounded-t-lg swiper-slide-active aos-init" data-aos="fade-up" role="group">
                <Link to={`/details/${base64_encode(listing_id.toString())}/${alias}`} className="relative overflow-hidden rounded-t-lg group">
                    {/* {images !== ""
                        ?
                        (<img className="w-full group-hover:scale-105 overflow-hidden transition-all h-80 object-cover rounded-t-lg" src={images.includes("base64") ? ((JSON.parse(images)[0]["base64"]).includes('/images/listing/') ? `/api${JSON.parse(images)[0]["base64"]}` : JSON.parse(images)[0]["base64"]) : images} alt={title} />)
                        :
                        (<img className="w-full group-hover:scale-105 overflow-hidden transition-all h-80 object-cover rounded-t-lg" src={`https://maps.googleapis.com/maps/api/staticmap?autoscale=2&size=400x400&maptype=roadmap&key=AIzaSyDQQPvj2WRZrF3bBXyrrGI2vVvojSDhjSs&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0x0a1d38%7Clabel:1%7C4040+${encodeURIComponent(address)}`} alt={title} />)
                    } */}

                    {images !== ""
                        ?
                        (<LazyImage className="w-full group-hover:scale-105 overflow-hidden transition-all h-80 object-cover rounded-t-lg" src={images.includes("base64") ? ((JSON.parse(images)[0]["base64"]).includes('/images/listing/') ? `/api${JSON.parse(images)[0]["base64"]}` : JSON.parse(images)[0]["base64"]) : images} alt={title} />)
                        :
                        (<LazyImage className="w-full group-hover:scale-105 overflow-hidden transition-all h-80 object-cover rounded-t-lg" src={`https://maps.googleapis.com/maps/api/staticmap?autoscale=2&size=400x400&maptype=roadmap&key=AIzaSyDQQPvj2WRZrF3bBXyrrGI2vVvojSDhjSs&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0x0a1d38%7Clabel:1%7C4040+${encodeURIComponent(address)}`} alt={title} />)
                    }

                    <div className="absolute bottom-0 z-10 top-1/2 flex items-start flex-col justify-end left-0 pb-5 pl-5 pt-2 card-linear-gradient w-full">
                        <div className="flex text-white items-center mb-2">
                            <h2 className="text-xl capitalize font-semibold mr-2">
                                {title}
                            </h2>
                        </div>
                        <p className="text-sm font-normal text-white mb-4">{address}</p>
                    </div>
                </Link>
                <div className="flex relative z-10 justify-between p-6 bg-gray-100 dark:bg-foreground rounded-b-lg">
                    <div className="flex">
                        <div className="flex">
                            <svg className={`w-5 h-auto flex-shrink-0 ${rating >= 1 ? 'text-yellow-400' : 'text-gray-300'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                            <svg className={`w-5 h-auto flex-shrink-0 ${rating >= 2 ? 'text-yellow-400' : 'text-gray-300'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                            <svg className={`w-5 h-auto flex-shrink-0 ${rating >= 3 ? 'text-yellow-400' : 'text-gray-300'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                            <svg className={`w-5 h-auto flex-shrink-0 ${rating >= 4 ? 'text-yellow-400' : 'text-gray-300'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                            <svg className={`w-5 h-auto flex-shrink-0 ${rating >= 5 ? 'text-yellow-400' : 'text-gray-300'}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                        </div>
                        <span className="text-base mx-2 dark:text-gray-100 font-medium">{rating}</span>
                        <span className="text-gray-500 dark:text-gray-400">({`${reviews} reviews`})</span>
                    </div>
                </div>
            </div>
        ))
    }

    // const trandingListing = () => {
    //     return businesses_list.sort(() => Math.random() - Math.random()).slice(0, 4).map(({ alias, name, image_url, rating, review_count, is_closed, location }, index) => (
    //         <div key={index} className="swiper-slide overflow-hidden rounded-t-lg swiper-slide-active aos-init" data-aos="fade-up" role="group" aria-label={`${index} / ${4}`} style={{ width: '385.333px', marginRight: '30px' }}>
    //             <Link to={`/details/${alias}`} className="relative overflow-hidden rounded-t-lg group">
    //                 <div className="absolute z-10 flex items-center mt-5 ml-4">
    //                     {/* <div className="bg-white rounded-full px-3 py-1 flex items-center">
    //                                             <svg className="w-3 h-3 text-green-500 mr-1" width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                                                 <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor" />
    //                                             </svg>
    //                                             <span className="text-xs font-medium">Instant Booking</span>
    //                                         </div> */}
    //                     <div className="bg-white rounded-full mb-2 px-3 py-1 mr-2 flex items-center">
    //                         <svg className="w-3 h-3 text-green-500 mr-1" width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                             <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor" />
    //                         </svg>
    //                         {!is_closed ? (<span className="text-xs font-medium">Open</span>) : (<span className="text-xs font-medium">Closed</span>)}
    //                     </div>
    //                     <div className="bg-white rounded-full mb-2 px-3 py-1 mr-2 flex items-center">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mr-1 text-green-500" viewBox="0 0 20 20" fill="currentColor">
    //                             <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
    //                         </svg>
    //                         <span className="text-xs font-medium">Featured</span>
    //                     </div>
    //                     {rating === 5 && (<div className="bg-white rounded-full mb-2 px-3 py-1 mr-2 flex items-center">
    //                         <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 text-yellow-500 mr-1" viewBox="0 0 20 20" fill="currentColor">
    //                             <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    //                         </svg>
    //                         <span className="text-xs font-medium">Rated</span>
    //                     </div>)}
    //                     <div className="bg-white rounded-full mb-2 px-3 py-1 mr-2 flex items-center">
    //                         <svg className="w-3 h-3 mr-1" width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                             <path d="M4.6498 2.39995H7.3498V1.64995C7.3498 1.40151 7.55043 1.19995 7.7998 1.19995C8.04918 1.19995 8.2498 1.40151 8.2498 1.64995V2.39995H8.9998C9.66168 2.39995 10.1998 2.93714 10.1998 3.59995V9.59995C10.1998 10.2618 9.66168 10.8 8.9998 10.8H2.9998C2.33699 10.8 1.7998 10.2618 1.7998 9.59995V3.59995C1.7998 2.93714 2.33699 2.39995 2.9998 2.39995H3.7498V1.64995C3.7498 1.40151 3.95043 1.19995 4.1998 1.19995C4.44918 1.19995 4.6498 1.40151 4.6498 1.64995V2.39995ZM2.6998 9.59995C2.6998 9.76495 2.83405 9.89995 2.9998 9.89995H8.9998C9.1648 9.89995 9.2998 9.76495 9.2998 9.59995V4.79995H2.6998V9.59995Z" fill="currentColor" />
    //                         </svg>
    //                         <span className="text-xs font-medium">Mon-Sat</span>
    //                     </div>
    //                 </div>
    //                 <img className="w-full group-hover:scale-105 overflow-hidden transition-all h-80 object-cover rounded-t-lg" src={image_url} alt={name} />
    //                 <div className="absolute bottom-0 z-10 top-1/2 flex items-start flex-col justify-end left-0 pb-5 pl-5 pt-2 card-linear-gradient w-full">
    //                     {/* <span className="bg-pink-600 text-xs text-white rounded-full px-3 py-1 mb-3 inline-flex">Coaching</span> */}
    //                     <div className="flex text-white items-center mb-2">
    //                         <h2 className="text-xl capitalize font-semibold mr-2">
    //                             {name}
    //                         </h2>
    //                         {/* <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                             <path d="M15.6562 4.34313C16.4688 5.15625 16.6906 6.31875 16.4031 7.34688C17.3531 7.85 18 8.85 18 10C18 11.15 17.3531 12.15 16.4031 12.6531C16.7188 13.6812 16.4688 14.8156 15.6562 15.6562C14.8156 16.4688 13.6812 16.6906 12.6531 16.4031C12.15 17.3531 11.15 18 10 18C8.85 18 7.85 17.3531 7.34688 16.4031C6.31875 16.7188 5.15625 16.4688 4.34313 15.6562C3.53 14.8156 3.28125 13.6812 3.59687 12.6531C2.64687 12.15 2 11.15 2 10C2 8.85 2.64687 7.85 3.59687 7.34688C3.25312 6.31875 3.53 5.15625 4.34313 4.34313C5.15625 3.53 6.31875 3.28125 7.34688 3.59687C7.85 2.64687 8.85 2 10 2C11.15 2 12.15 2.64687 12.6531 3.59687C13.6812 3.25312 14.8156 3.53 15.6562 4.34313Z" fill="#00AB55" />
    //                             <path d="M7 10L8.64645 11.6464C8.84171 11.8417 9.15829 11.8417 9.35355 11.6464L13 8" stroke="white" strokeWidth="1.2" strokeLinecap="round" />
    //                         </svg> */}
    //                     </div>
    //                     <p className="text-sm font-normal text-white mb-4">{`${location.address1} ${location.address2} ${location.city}, ${location.state} ${location.zip_code}`}</p>
    //                 </div>
    //             </Link>
    //             <div className="flex relative z-10 justify-between p-6 bg-gray-100 dark:bg-foreground rounded-b-lg">
    //                 <div className="flex">
    //                     <div className="flex">
    //                         <svg className="w-5 h-auto flex-shrink-0 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
    //                             <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    //                         </svg>
    //                         <svg className="w-5 h-auto flex-shrink-0 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
    //                             <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    //                         </svg>
    //                         <svg className="w-5 h-auto flex-shrink-0 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
    //                             <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    //                         </svg>
    //                         <svg className="w-5 h-auto flex-shrink-0 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
    //                             <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    //                         </svg>
    //                         <svg className="w-5 h-auto flex-shrink-0 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
    //                             <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    //                         </svg>
    //                     </div>
    //                     <span className="text-base mx-2 dark:text-gray-100 font-medium">{rating}</span>
    //                     <span className="text-gray-500 dark:text-gray-400">({review_count})</span>
    //                 </div>
    //                 {/* <button type="button" className="text-gray-300 dark:text-gray-500 hover:text-pink-600 dark:hover:text-pink-600">
    //                     <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor">
    //                         <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
    //                     </svg>
    //                 </button> */}
    //             </div>
    //         </div>
    //     ))
    // }

    if (inViewport) {
        if (loading && featuredList.length === 0) {
            //console.log("loading featuredList");
            fetchFeaturedListing(5);
        }
        //console.log('in viewport:', ref.current);
    }

    return (
        <>
            {/* start::trending-listing  */}
            <section className={!hide ? `lg:py-20 border-b border-gray-200 dark:border-foreground dark:bg-background` : `hidden`} id="TrandingListing" ref={ref}>
                <div className="container mx-auto lg:p-0 p-5">
                    <div className="flex lg:flex-row flex-col lg:text-left text-center flex-wrap items-center justify-between mb-12">
                        <div className="lg:mb-0 mb-4 aos-init" data-aos="fade-up">
                            <h2 className="text-2xl lg:text-3xl font-medium capitalize mb-2 dark:text-gray-100">
                                Trending Listing
                            </h2>
                            <p className="text-sm lg:text-base text-gray-500 dark:text-gray-400 font-normal">
                                A selection of listing verified for quality
                            </p>
                        </div>
                        {/* <div data-aos="fade-up" className="aos-init">
                            <a href="listing-list.html" className="text-gray-500 dark:text-gray-400 hover:text-blue-500 flex items-center">
                                Explore More
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 ml-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </a>
                        </div> */}
                    </div>
                    {/* Slider main container */}
                    <div className="relative">
                        <div className="swiper swiper-initialized swiper-horizontal swiper-pointer-events swiper-backface-hidden">
                            {/* Additional required wrapper */}
                            <div className="swiper-wrapper relative h-full" id="swiper-wrapper-b71c45459a6561e3" aria-live="polite" style={{ transform: 'translate3d(0px, 0px, 0px)' }}>
                                {/* Slides */}
                                {/* {trandingListing()} */}
                                {/* {createLoadingLayout()} */}
                                {loading ? createLoadingLayout() : trandingListing()}
                            </div>
                            {/* If we need pagination */}
                            <div className="swiper-pagination" />
                            {/* If we need navigation buttons */}
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                        </div>
                        <div className="swiper-button-prev left-2 lg:-left-3 after:hidden swiper-button-disabled" tabIndex={0} role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-cd31810942f34d10c7" aria-disabled="true">
                            <button type="button" className="bg-white dark:bg-foreground text-blue-500 hover:bg-blue-500 hover:text-white rounded-full p-3 shadow-small-content-1" aria-label="Previous slide">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="h-6 w-6" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                </svg>
                            </button>
                        </div>
                        <div className="swiper-button-next absolute right-2 lg:-right-3 after:hidden" tabIndex={0} role="button" aria-label="Next slide" aria-controls="swiper-wrapper-cd31810942f34d10c7" aria-disabled="false">
                            <button type="button" className="bg-white dark:bg-foreground text-blue-500 hover:bg-blue-500 hover:text-white rounded-full p-3 shadow-small-content-1" aria-label="Next slide">
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="relative w-24 mx-auto mt-10" />
                </div>
            </section>
            {/* end::trending-listing  */}
        </>
    )
}

export default TrendingListing