import React from 'react'

const VisitOurFacility = () => {
    return (
        <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7 p-6">
            <div className="text-lg font-bold mb-4 text-gray-700 dark:text-gray-100">
                Visit Our Facility
            </div>
            <iframe width={903} height={315} src="https://www.youtube.com/embed/0njF9gYqByw" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="rounded-lg w-full" />
        </div>
    )
}

export default VisitOurFacility