import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getCookie, setCookie, checkRole } from '../../func';

const Footer = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    if (getCookie("token") !== undefined && getCookie("token") !== null) {
      //user is loggedin
      setUserLoggedIn(true);
      setUserRole(checkRole(getCookie("token")));
    }

    // Respond to the `storage` event
    function storageEventHandler(event) {
      if (localStorage.getItem("isLoggedIn") !== null) {
        setUserLoggedIn(true);
        setUserRole(checkRole(getCookie("token")));
      }
      else {
        setUserLoggedIn(false);
        setUserRole('');
      }
    }
    // Hook up the event handler
    window.addEventListener("storage", storageEventHandler);
    return () => {
      // Remove the handler when the component unmounts
      window.removeEventListener("storage", storageEventHandler);
    };
  }, [])

  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const CreateRoleBasedNavigation = () => {
    if (userRole === "admin") {
      return <li className="mb-2">
        <Link to="/admin/dashboard" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
          <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Dashboard
        </Link>
      </li>
    }
    else if (userRole === "member") {
      return <li className="mb-2">
        <Link to="/admin/add_listing" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
          <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Dashboard
        </Link>
      </li>
    }
    else if (userRole === "author") {
      return <li className="mb-2">
        <Link to="/admin/add_post" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
          <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Dashboard
        </Link>
      </li>
    }
  }

  return (
    <>
      {/* start::footer  */}
      <footer className="dark:bg-foreground px-5 lg:px-0" style={{
        backgroundImage: "url('/img/bg-footer.webp')", backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat', opacity: "1"
      }}>
        <div className="container mx-auto py-20" >
          <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-7">
            <div>
              <div className="text-base font-semibold text-white dark:text-gray-700 mb-4">
                Quick Links
              </div>
              <ul>
                <li className="mb-2">
                  <Link to="/" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Home
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/about-us" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>About Us
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/contact-us" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Contact Us
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/blog" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Blog
                  </Link>
                </li>
                {
                  userLoggedIn
                    ?
                    (
                      <CreateRoleBasedNavigation />
                    )
                    :
                    (
                      <>
                        <li className="mb-2">
                          <Link to="/login" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                            <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Login
                          </Link>
                        </li>
                        <li className="mb-2">
                          <Link to="/register" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                            <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Register
                          </Link>
                        </li>
                      </>
                    )
                }
              </ul>
            </div>
            <div>
              <Link to={"/opioid-addiction"} className="text-base font-semibold text-white dark:text-gray-700 mb-4">
                Opioid Addiction
              </Link>
              <ul className="mt-4">
                <li className="mb-2">
                  <Link to="/signs-of-opioid-addiction" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Signs of Opioid Addiction
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/effects-of-opioid-addiction" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Effects of Opioid Addiction
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/signs-of-opioid-overdose" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Signs of Opioid Overdose
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/opioid-addiction-risk-factors" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Opioid Addiction Risk Factors
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/am-i-addicted-to-opioids" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Am I Addicted to Opioids?
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <Link to={"/find-opioid-treatment"} className="text-base font-semibold text-white dark:text-gray-700 mb-4">
                Find Opioid Treatment
              </Link>
              <ul className="mt-4">
                <li className="mb-2">
                  <Link to="/detox" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Detox
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/residential-treatment" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Residential Treatment
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/medication-assisted-treatment" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Medication-Assisted Treatment
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/virtual-treatment" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Virtual Treatment
                  </Link>
                </li>
                {/* <li className="mb-2">
                  <Link to="/outpatient-treatment" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Outpatient Treatment
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/paying-for-treatment" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Paying For Treatment
                  </Link>
                </li> */}
              </ul>
            </div>

            <div>
              <Link to={"/success-stories-recovery"} className="text-base font-semibold text-white dark:text-gray-700 mb-4">
                Success Stories + Recovery
              </Link>
              <ul className="mt-4">
                <li className="mb-2">
                  <Link to="/opioid-addiction-stories" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Opioid Addiction Stories
                  </Link>
                </li>
                {/* <li className="mb-2">
                  <Link to="/sobriety-engine" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Sobriety Engine
                  </Link>
                </li> */}
                <li className="mb-2">
                  <Link to="/recovery-resources" className="flex items-center text-white dark:text-gray-200 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#ffffff" /></svg>Recovery Resources (Blog)
                  </Link>
                </li>
              </ul>
            </div>

            <div>
              <div className="text-base font-semibold text-white dark:text-gray-200 mb-4">
                Contact Information
              </div>
              <div className="flex mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-2 flex-shrink-0 text-white dark:text-gray-200" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-white dark:text-gray-200">
                  4300 Lynn Street Brookline, MA 02146
                </div>
              </div>
              <div className="flex mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-2 flex-shrink-0 text-white dark:text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                <div className="text-sm text-white dark:text-gray-200">
                  617-278-3741
                </div>
              </div>
              <div className="flex mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-2 flex-shrink-0 text-white dark:text-gray-200" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <div className="text-sm text-white dark:text-gray-200">
                  contact@opioid-rehab.com
                </div>
              </div>
            </div>

          </div>
          {/* <div className="grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-7">
            <div>
              <div className="text-base font-semibold text-gray-700 dark:text-gray-100 mb-4">
                Quick Links
              </div>
              <ul>
                <li className="mb-2">
                  <a href="#" className="flex items-center text-gray-500 dark:text-gray-400 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" /></svg>Home
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="flex items-center text-gray-500 dark:text-gray-400 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" /></svg>Buy Property
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="flex items-center text-gray-500 dark:text-gray-400 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" /></svg>Rent an Apartment
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="flex items-center text-gray-500 dark:text-gray-400 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" /></svg>Sell Property
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="flex items-center text-gray-500 dark:text-gray-400 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" /></svg>About Us
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <div className="text-base opacity-0 font-semibold text-gray-700 dark:text-gray-100 mb-4">
                Quick Links
              </div>
              <ul>
                <li className="mb-2">
                  <a href="#" className="flex items-center text-gray-500 dark:text-gray-400 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" /></svg>List of Property
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="flex items-center text-gray-500 dark:text-gray-400 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" /></svg>Help
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="flex items-center text-gray-500 dark:text-gray-400 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" /></svg>FAQs
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="flex items-center text-gray-500 dark:text-gray-400 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" /></svg>Terms &amp; Conditions
                  </a>
                </li>
                <li className="mb-2">
                  <a href="#" className="flex items-center text-gray-500 dark:text-gray-400 font-normal text-sm">
                    <svg className="w-3 h-3 flex-shrink-0 mr-1" width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" /></svg>Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <div className="text-base font-semibold text-gray-700 dark:text-gray-100 mb-4">
                Contact Information
              </div>
              <div className="flex mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-2 flex-shrink-0 text-gray-500 dark:text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  123 Burdin St. Newyork, USA.
                </div>
              </div>
              <div className="flex mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-2 flex-shrink-0 text-gray-500 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                </svg>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  +(123) 014 235 456
                </div>
              </div>
              <div className="flex mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-2 flex-shrink-0 text-gray-500 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  accommo@info.com
                </div>
              </div>
              <div className="flex mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-2 flex-shrink-0 text-gray-500 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                </svg>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  info@accommo.com
                </div>
              </div>
              <div className="flex mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 mr-2 flex-shrink-0 text-gray-500 dark:text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                </svg>
                <div className="text-sm text-gray-500 dark:text-gray-400">ui-lib</div>
              </div>
            </div>
            <div className="lg:col-span-2 flex flex-col">
              <div className="max-w-[482px] lg:ml-auto ml-0">
                <h3 className="lg:text-3xl text-2xl font-medium text-gray-700 mb-3 dark:text-gray-100">
                  Subscribe Our Newsletter
                </h3>
                <p className="text-base mb-10 text-gray-500 dark:text-gray-400">
                  Get important update to your email.
                </p>
                <div className="inline-flex bg-white dark:bg-background text-gray-400 p-2 rounded-lg shadow-front-4">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-w-5 w-5 mx-2 shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <input type="email" placeholder="Enter your email" className="focus:outline-none w-full dark:text-gray-400 dark:bg-background" />
                  <button type="button" className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg ml-0 lg:ml-10">
                    Subscribe
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="text-base py-5 text-center" style={{ backgroundColor: "#010010", }}>
          <div className="container mx-auto flex justify-between items-center flex-wrap">
            <p className="text-white dark:text-gray-200 text-left font-normal text-sm lg:mb-0 mb-4">
              Developed by Grands.Digital
            </p>
            <p className="text-white dark:text-gray-200 text-center font-normal text-sm lg:mb-0 mb-4">
              Copyright (C) Opioid-Rehab.com 2024. All rights reserved.
            </p>
            <div className="flex flex-wrap">
              <button onClick={() => openInNewTab(process.env.REACT_APP_Facebook_PAGE)} className="text-white hover:text-blue-500 mr-3" aria-label="Facebook Page">
                <svg className="w-[20px] h-[20px]" width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 10.5625C18 6.14422 14.4183 2.5625 10 2.5625C5.58172 2.5625 2 6.14422 2 10.5625C2 14.5555 4.92547 17.8652 8.75 18.4653V12.875H6.71875V10.5625H8.75V8.8C8.75 6.795 9.94438 5.6875 11.7717 5.6875C12.6467 5.6875 13.5625 5.84375 13.5625 5.84375V7.8125H12.5538C11.56 7.8125 11.25 8.42922 11.25 9.0625V10.5625H13.4688L13.1141 12.875H11.25V18.4653C15.0745 17.8652 18 14.5555 18 10.5625Z" fill="currentColor" />
                </svg>
              </button>
              <button onClick={() => openInNewTab(process.env.REACT_APP_TWITTER_PAGE)} className="text-white hover:text-blue-500 mr-3" aria-label="Twitter Page">
                <svg className="w-[20px] h-[20px]" width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.03344 16.563C13.0697 16.563 16.3722 11.5608 16.3722 7.22423C16.3722 7.0836 16.3691 6.93985 16.3628 6.79923C17.0052 6.33463 17.5597 5.75916 18 5.09985C17.4017 5.36605 16.7664 5.53991 16.1159 5.61548C16.8009 5.20493 17.3137 4.55997 17.5594 3.80017C16.915 4.18203 16.2104 4.4514 15.4756 4.59673C14.9806 4.0707 14.326 3.7224 13.6131 3.60569C12.9003 3.48898 12.1688 3.61036 11.5318 3.95105C10.8949 4.29175 10.3879 4.83279 10.0892 5.49053C9.7906 6.14827 9.71695 6.88606 9.87969 7.58985C8.575 7.52438 7.29862 7.18546 6.13332 6.59505C4.96802 6.00465 3.9398 5.17595 3.11531 4.16267C2.69627 4.88515 2.56804 5.74008 2.75669 6.55372C2.94534 7.36735 3.43671 8.07862 4.13094 8.54298C3.60975 8.52643 3.09998 8.38611 2.64375 8.1336V8.17423C2.64328 8.93242 2.9054 9.66738 3.38554 10.2542C3.86568 10.841 4.53422 11.2434 5.2775 11.393C4.7947 11.5251 4.28799 11.5443 3.79656 11.4492C4.0063 12.1013 4.41438 12.6716 4.96385 13.0805C5.51331 13.4895 6.17675 13.7167 6.86156 13.7305C5.69895 14.6437 4.26278 15.1391 2.78438 15.1367C2.52219 15.1363 2.26027 15.1203 2 15.0886C3.5019 16.0522 5.24902 16.5639 7.03344 16.563Z" fill="currentColor" />
                </svg>
              </button>
              <button onClick={() => openInNewTab(process.env.REACT_APP_LINKEDIN_PAGE)} className="text-white hover:text-blue-500 mr-3" aria-label="LinkedIn Page">
                <svg className="w-[20px] h-[20px]" width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.8156 2.5625H3.18125C2.52812 2.5625 2 3.07813 2 3.71563V17.4062C2 18.0438 2.52812 18.5625 3.18125 18.5625H16.8156C17.4688 18.5625 18 18.0438 18 17.4094V3.71563C18 3.07813 17.4688 2.5625 16.8156 2.5625ZM6.74687 16.1969H4.37188V8.55937H6.74687V16.1969ZM5.55938 7.51875C4.79688 7.51875 4.18125 6.90312 4.18125 6.14375C4.18125 5.38438 4.79688 4.76875 5.55938 4.76875C6.31875 4.76875 6.93437 5.38438 6.93437 6.14375C6.93437 6.9 6.31875 7.51875 5.55938 7.51875ZM15.6344 16.1969H13.2625V12.4844C13.2625 11.6 13.2469 10.4594 12.0281 10.4594C10.7937 10.4594 10.6062 11.425 10.6062 12.4219V16.1969H8.2375V8.55937H10.5125V9.60313H10.5437C10.8594 9.00313 11.6344 8.36875 12.7875 8.36875C15.1906 8.36875 15.6344 9.95 15.6344 12.0063V16.1969Z" fill="currentColor" />
                </svg>
              </button>
              <button onClick={() => openInNewTab(process.env.REACT_APP_INSTAGRAM_PAGE)} className="text-white hover:text-blue-500 mr-3" aria-label="Instagram Page">
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="currentColor" className="w-[20px] h-[20px]" viewBox="0 0 18 16">
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                </svg>
              </button>

              {/* <Link to={"#"} className="text-white hover:text-blue-500 mr-3">
                <svg className="w-[20px] h-[20px]" width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 2.5625C5.5816 2.5625 2 6.1497 2 10.5761C2 14.1161 4.292 17.1201 7.4712 18.1793C7.8712 18.2529 8.0168 18.0057 8.0168 17.7929C8.0168 17.6033 8.0104 17.0985 8.0064 16.4305C5.7808 16.9145 5.3112 15.3561 5.3112 15.3561C4.948 14.4297 4.4232 14.1833 4.4232 14.1833C3.6968 13.6873 4.4784 13.6969 4.4784 13.6969C5.2808 13.7529 5.7032 14.5225 5.7032 14.5225C6.4168 15.7465 7.576 15.3929 8.0312 15.1881C8.1048 14.6705 8.3112 14.3177 8.54 14.1177C6.764 13.9153 4.896 13.2273 4.896 10.1569C4.896 9.2825 5.208 8.5665 5.7192 8.0065C5.6368 7.8041 5.3624 6.9889 5.7976 5.8865C5.7976 5.8865 6.4696 5.6705 7.9976 6.7073C8.65021 6.52931 9.32355 6.43865 10 6.4377C10.68 6.4409 11.364 6.5297 12.0032 6.7073C13.5304 5.6705 14.2008 5.8857 14.2008 5.8857C14.6376 6.9889 14.3624 7.8041 14.2808 8.0065C14.7928 8.5665 15.1032 9.2825 15.1032 10.1569C15.1032 13.2353 13.232 13.9129 11.4504 14.1113C11.7376 14.3585 11.9928 14.8473 11.9928 15.5953C11.9928 16.6657 11.9832 17.5305 11.9832 17.7929C11.9832 18.0073 12.1272 18.2569 12.5336 18.1785C14.1266 17.6442 15.5115 16.6227 16.4924 15.2585C17.4733 13.8943 18.0007 12.2564 18 10.5761C18 6.1497 14.4176 2.5625 10 2.5625Z" fill="currentColor" />
                </svg>
              </Link>
              <Link to={"#"} className="text-white hover:text-blue-500 mr-3">
                <svg className="w-[20px] h-[20px]" width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10 2.5625C5.58352 2.5625 2 6.14602 2 10.5625C2 14.979 5.58352 18.5625 10 18.5625C14.4078 18.5625 18 14.979 18 10.5625C18 6.14602 14.4078 2.5625 10 2.5625ZM15.2842 6.25013C16.2386 7.41283 16.8113 8.89655 16.8287 10.5017C16.6031 10.4584 14.347 9.9985 12.0737 10.2849C12.0217 10.172 11.9783 10.0505 11.9263 9.9291C11.7874 9.5994 11.6313 9.261 11.475 8.93995C13.9913 7.9161 15.1367 6.44103 15.2842 6.25013ZM10 3.74255C11.7353 3.74255 13.3232 4.39331 14.5293 5.46055C14.4078 5.63409 13.3752 7.01369 10.9458 7.92475C9.82645 5.86835 8.5857 4.18506 8.3948 3.92475C8.9067 3.80328 9.4447 3.74255 10 3.74255ZM7.0933 4.38462C7.2755 4.62757 8.49025 6.31955 9.6269 8.33255C6.43384 9.1829 3.61388 9.16555 3.31019 9.16555C3.75271 7.0484 5.18438 5.28701 7.0933 4.38462ZM3.16269 10.5712C3.16269 10.5018 3.16269 10.4323 3.16269 10.3629C3.4577 10.3716 6.77224 10.415 10.1822 9.39115C10.3818 9.7729 10.564 10.1634 10.7375 10.5538C10.6508 10.5799 10.5553 10.6059 10.4685 10.6319C6.94577 11.7685 5.07158 14.8749 4.9154 15.1351C3.8308 13.9291 3.16269 12.3239 3.16269 10.5712ZM10 17.3998C8.4208 17.3998 6.96312 16.8618 5.80911 15.9594C5.93058 15.7078 7.31885 13.0354 11.1713 11.6905C11.1887 11.6818 11.1974 11.6818 11.2148 11.6732C12.1779 14.1634 12.5683 16.2545 12.6725 16.8532C11.8482 17.2089 10.9458 17.3998 10 17.3998ZM13.8091 16.2284C13.7397 15.8119 13.3752 13.8163 12.4816 11.3608C14.6248 11.0224 16.4989 11.5777 16.7332 11.6558C16.4382 13.556 15.3449 15.1959 13.8091 16.2284Z" fill="currentColor" />
                </svg>
              </Link> */}
            </div>
          </div>
        </div>
      </footer>
      {/* end::footer  */}
    </>
  )
}

export default Footer