//import logo from './logo.svg';
import './App.css';

import { Routes, Route } from "react-router-dom";
import AmIAddictedToOpioids from "./containers/pages/AmIAddictedToOpioids";
import Detox from "./containers/pages/Detox";
import EffectsofOpioidDependency from "./containers/pages/EffectsofOpioidDependency";
import FindOpioidTreatment from "./containers/pages/FindOpioidTreatment";

import Home from './containers/pages/Home';
import MedicationAssistedTreatment from "./containers/pages/MedicationAssistedTreatment";
import OpioidAddiction from "./containers/pages/OpioidAddiction";
import OpioidAddictionRiskFactors from "./containers/pages/OpioidAddictionRiskFactors";
import OpioidAddictionStories from "./containers/pages/OpioidAddictionStories";
import OutpatientTreatment from "./containers/pages/OutpatientTreatment";
import PayingForTreatment from "./containers/pages/PayingForTreatment";
import RecoveryResources from "./containers/pages/RecoveryResources";
import ResidentialTreatment from "./containers/pages/ResidentialTreatment";
// import Search from "./containers/pages/Search";
// import Details from './containers/pages/Details';
import SignsOfOpioidAddiction from "./containers/pages/SignsOfOpioidAddiction";
import SignsOfOpioidOverdose from "./containers/pages/SignsOfOpioidOverdose";
import SobrietyEngine from "./containers/pages/SobrietyEngine";
import SuccessStoriesRecovery from "./containers/pages/SuccessStoriesRecovery";
import VirtualTreatment from "./containers/pages/VirtualTreatment";

import V2Search from './containers/pages/v2/V2Search';
import V2Details from './containers/pages/v2/V2Details';

// import V3Search from './containers/pages/v3/V3Search';
// import V4Search from './containers/pages/v4/V4Search';
import Login from './containers/pages/admin/Login';
import ForgetPassword from './containers/pages/admin/ForgetPassword';
import AddListing from './containers/pages/admin/AddListing';
import AddReview from './containers/pages/admin/AddReviews';
import MyListing from './containers/pages/admin/MyListing';
import EditListing from './containers/pages/admin/EditListing';
import MyIncompleteListing from './containers/pages/admin/MyIncompleteListing';
import AddPost from './containers/pages/admin/AddPost';
import EditPost from './containers/pages/admin/EditPost';
import Blog from './containers/pages/Blog';
import Post from './containers/pages/Post';
import NotFound from './containers/pages/NotFound';
import ContactUs from './containers/pages/contact-us';
import AboutUs from './containers/pages/about-us';
import Dashboard from './containers/pages/admin/Dashboard';
import ResetPassword from './containers/pages/admin/ResetPassword';
import Users from './containers/pages/admin/Users';
import ChangePassword from './containers/pages/admin/ChangePassword';
import Verify from './containers/pages/admin/Verify';
import Profile from './containers/pages/admin/Profile';
import AllListings from './containers/pages/admin/AllListings';
import Register from './containers/pages/admin/Register';

function App() {
  return (
    <>
      {/* <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div> */}

      <Routes>
        <Route path="/" element={<Home />} />

        {/* old working routes
        <Route path="/search/:state" element={<Search />} />
        <Route path="/search/:state/:city" element={<Search />} />

        <Route path="/details/:alias" element={<Details />} />

        <Route path="/v2/search/:state" element={<V2Search />} />
        <Route path="/v2/search/:state/:city" element={<V2Search />} />
        <Route path="/v2/details/:alias" element={<V2Details />} />
        <Route path="/v2/details/:id/:alias" element={<V2Details />} /> 
        old working routes*/}

        {/* <Route path="/v1/search/:state" element={<Search />} />
        <Route path="/v1/search/:state/:city" element={<Search />} />

        <Route path="/v1/details/:alias" element={<Details />} /> */}

        <Route path="/search/:state" element={<V2Search />} />
        <Route path="/search/:state/:city" element={<V2Search />} />
        <Route path="/details/:alias" element={<V2Details />} />
        <Route path="/details/:id/:alias" element={<V2Details />} />

        {/* <Route path="/v3/search" element={<V3Search />} />

        <Route path="/v4/search" element={<V4Search />} /> */}

        <Route path="/opioid-addiction" element={<OpioidAddiction />} />
        <Route path="/signs-of-opioid-addiction" element={<SignsOfOpioidAddiction />} />
        <Route path="/effects-of-opioid-dependency" element={<EffectsofOpioidDependency />} />
        <Route path="/signs-of-opioid-overdose" element={<SignsOfOpioidOverdose />} />
        <Route path="/opioid-addiction-risk-factors" element={<OpioidAddictionRiskFactors />} />
        <Route path="/am-i-addicted-to-opioids" element={<AmIAddictedToOpioids />} />

        <Route path="/find-opioid-treatment" element={<FindOpioidTreatment />} />
        <Route path="/detox" element={<Detox />} />
        <Route path="/residential-treatment" element={<ResidentialTreatment />} />
        <Route path="/medication-assisted-treatment" element={<MedicationAssistedTreatment />} />
        <Route path="/virtual-treatment" element={<VirtualTreatment />} />
        <Route path="/outpatient-treatment" element={<OutpatientTreatment />} />
        <Route path="/paying-for-treatment" element={<PayingForTreatment />} />

        <Route path="/success-stories-recovery" element={<SuccessStoriesRecovery />} />
        <Route path="/opioid-addiction-stories" element={<OpioidAddictionStories />} />
        <Route path="/sobriety-engine" element={<SobrietyEngine />} />
        {/* <Route path="/recovery-resources" element={<RecoveryResources />} /> */}
        <Route path="/recovery-resources" element={<Blog />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/post"element={<Post /> } />
        <Route path="/post/:postid/:slug"element={<Post /> } />

        <Route path="/admin/login" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        <Route path="/admin/forget_password" element={<ForgetPassword />} />
        <Route path="/admin/reset_password/:token" element={<ResetPassword />} />        
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/change_password" element={<ChangePassword />} />
        <Route path="/admin/verify/:token" element={<Verify />} />
        <Route path="/admin/users" element={<Users />} />

        <Route path="/admin/profile" element={<Profile />} />
        <Route path="/admin/profile/:user_token" element={<Profile />} />

        <Route path="/admin/all_listings" element={<AllListings />} />
        <Route path="/admin/add_listing" element={<AddListing />} />
        <Route path="/admin/edit_listing/:listing_id" element={<EditListing />} />
        <Route path="/admin/add_review/:listing_id" element={<AddReview />} />
        <Route path="/admin/my_listing" element={<MyListing />} />
        <Route path="/admin/my_incomplete_listing" element={<MyIncompleteListing />} />

        <Route path="/admin/add_post" element={<AddPost />} />
        <Route path="/admin/edit_post/:postid" element={<EditPost />} />

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/404" element={<NotFound />} />
      </Routes>

    </>
  );
}

export default App;
