import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const MedicationAssistedTreatment = () => {

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Medication Assisted Treatment</h1>
                <p className="text-white mt-2">Medication For Opioid Use Disorder (MOUD)</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    {/* <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h5> */}

                    <div className="grid lg:grid-cols-2 md:grid-cols-2 lg:gap-0 gap-7 mt-8 mb-20">

                      <div className='grid-cols-1'>
                        <p className="text-gray-500 text-base dark:text-gray-300 mb-5">There are several explanations for why someone could use opioids. The United States has over 2 million individuals with opioid use disorder (OUD) associated with prescription opioids. An additional 25,000 persons suffer from an OUD connected to heroin.</p>

                        <p className="text-gray-500 text-base dark:text-gray-300">Evidence-based methods are available to help people safely cut back or quit taking opioids. One such strategy is the use of medications for opioid use disorder (MOUD), which work best for long-term effectiveness when someone chooses to begin treatment (as opposed to being forced or required to).</p>
                      </div>

                      <div className='grid-cols-1 flex h-full items-center'>
                        {/* <img src="https://www.thehopehouse.com/wp-content/uploads/2020/11/Client-speaking-with-doctor-in-opioid-rehab.jpg" width="730" height="475" alt="" class="lazyloaded" data-ll-status="loaded" /> */}
                        <img src="https://harmreduction.org/wp-content/uploads/2020/07/MOUD-01.png" alt="" class="w-full h-64 object-cover rounded-t-lg p-4" />
                      </div>

                    </div>

                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      BENEFITS OF MEDICATIONS FOR OPIOID USE DISORDER
                    </h5>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      It's been shown that this evidence-based intervention:
                    </h4>

                    <div className="grid lg:grid-cols-5 md:grid-cols-4 lg:gap-5 mt-8 mb-20">
                      <div className="flex items-center grid-cols-1 shadow-front-3 rounded-lg group bg-slate-100 dark:bg-background">
                        <p className="text-md font-medium text-gray-500 text-base dark:text-gray-300 p-4">
                          Encourage those who decide to cut back or quit using opioids
                        </p>
                      </div>
                      <div className="flex items-center grid-cols-1 shadow-front-3 rounded-lg group bg-slate-100 dark:bg-background">
                        <p className="text-md font-medium text-gray-500 text-base dark:text-gray-300 p-4">
                          Boost the probability that an individual will stay drug-free
                        </p>
                      </div>
                      <div className="flex items-center grid-cols-1 shadow-front-3 rounded-lg group bg-slate-100 dark:bg-background">
                        <p className="text-md font-medium text-gray-500 text-base dark:text-gray-300 p-4">
                          Lessen the use of opioids and the signs and symptoms of opioid use disorder
                        </p>
                      </div>
                      <div className="flex items-center grid-cols-1 shadow-front-3 rounded-lg group bg-slate-100 dark:bg-background">
                        <p className="text-md font-medium text-gray-500 text-base dark:text-gray-300 p-4">
                          Lower the chance of the spread of infectious diseases
                        </p>
                      </div>
                      <div className="flex items-center grid-cols-1 shadow-front-3 rounded-lg group bg-slate-100 dark:bg-background">
                        <p className="text-md font-medium text-gray-500 text-base dark:text-gray-300 p-4">
                          Decrease the likelihood of a fatality from an overdose
                        </p>
                      </div>
                    </div>


                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Different Kinds of Drugs for Opioid Use Disorder
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      We endorse methadone and buprenorphine as two forms of evidence-based treatments for opioid use disorder.
                    </p>

                    <div className="grid lg:grid-cols-2 md:grid-cols-2 mt-8 mb-8">
                      <div className="flex flex-col grid-cols-1 text-justify p-4 shadow-front-1 rounded-lg border border-gray-200 dark:border-gray-800 mr-1 bg-slate-100">
                        <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-1">
                          Buprenorphine
                        </h5>

                        <img src="https://harmreduction.org/wp-content/uploads/2020/07/MOUD-01.png" alt="" class="w-full h-48 object-cover rounded-t-lg mb-2" />

                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">What it is: an opioid receptor agonist that binds to the same receptors as full agonists but activates them less potently. This makes it a partial opioid agonist.</p>
                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">How it functions: lessens withdrawal symptoms and cravings without making you feel happy.</p>
                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">How to obtain it: under the Drug Addiction Treatment Act, licensed doctors may prescribe it.</p>
                      </div>

                      <div className="flex flex-col grid-cols-1 text-justify p-4 shadow-front-1 rounded-lg border border-gray-200 dark:border-gray-800 mr-1 bg-slate-100">
                        <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-1">
                          Methadone
                        </h5>

                        <img src="https://harmreduction.org/wp-content/uploads/2020/08/MOUD-03.jpg" alt="" class="w-full h-48 object-cover rounded-t-lg mb-2" />

                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">What it is: an opioid receptor-binding synthetic agonist</p>
                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">How it functions: it gets rid of cravings and withdrawal symptoms.</p>
                        <p className="text-gray-500 text-base dark:text-gray-500 mb-5">How to obtain it: It can only be administered by accredited opioid treatment centers.</p>
                      </div>
                    </div>


                    <h5 className="text-center text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6 mt-20">
                      Find Buprenorphine
                    </h5>

                    <p className="text-center text-gray-500 text-base dark:text-gray-300 mb-10">
                    Nationwide harm reduction groups are striving to ensure that everyone in need of opioid use disorder drugs may get them.
                    </p>

                    <div className="grid lg:grid-cols-2 md:grid-cols-2 mt-8 mb-8">
                      <div className="flex flex-col grid-cols-1 text-justify p-4 shadow-front-1 rounded-lg mr-1 bg-slate-100">
                        <p className="text-lg text-black dark:text-gray-500 mb-5">You can get resources by contacting your local syringe service program and harm reduction groups.</p>
                      </div>

                      <div className="flex flex-col grid-cols-1 text-justify p-4 shadow-front-1 rounded-lg mr-1 bg-slate-100">
                        <p className="text-lg text-black dark:text-gray-500 mb-5">The buprenorphine locator, maintained by SAMHSA, is a nationwide list of thousands of practitioners licensed to treat opioid use disorder.</p>
                        <p className="text-md text-black dark:text-gray-500 mb-5"><a className="border-b" href="https://www.samhsa.gov/medication-assisted-treatment/practitioner-program-data/treatment-practitioner-locator">Locate a local provider nearby.</a></p>
                        <p className="text-sm text-black dark:text-gray-500 mb-5">Note that the providers on this list have not been verified by us. To make sure the information is still current, you might want to give them a call in advance.</p>
                      </div>
                    </div>

                    {/* <div className='shadow-front-4 rounded-lg p-4 mt-20 bg-slate-100'>
                      <h5 className="text-center text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-800 pt-6">
                        Recent Wins
                      </h5>

                      <p className="text-center text-gray-500 text-base dark:text-gray-500 mb-10">
                        In 2019, National Harm Reduction Coalition partnered with <a className='text-blue-500 dark:text-gray-400 hover:text-blue-800' href="https://www.bridgetotreatment.org/">ED-Bridge</a> to expand access to buprenorphine in emergency room settings in California. Thanks to this effort, people seeking treatment for opioid use disorder can now access 24/7 treatment at 52 hospital sites on demand.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5} />}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default MedicationAssistedTreatment