import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

const ResidentialTreatment = () => {

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Residential Treatment</h1>
                <p className="text-white mt-2">Treatment for Heroin and Opioid Addiction</p>
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">
                <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" /> */}
                  {/* <img className="w-full rounded" src="img/Image/landing-page-image/building-2.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-3.jpg" alt="" />
                  <img className="w-full rounded" src="img/Image/landing-page-image/building-4.jpg" alt="" /> */}
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Residential Treatment: A Wholesome Method for Treating Opioid and Heroin Addiction
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      An intense, all-encompassing method called residential treatment is used to assist people in kicking their addiction to heroin and opiates. It entails residing at a treatment center where patients get a variety of therapies, organized support, and round-the-clock care. People who suffer from serious addictions, co-occurring mental health conditions, or who have not responded well to outpatient therapy are frequently advised to pursue this kind of care.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Physicians take into account a variety of elements while designing the most effective treatment plan for each patient with a drug use disorder, including:
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          How long have you been using opioids? What kinds of opioids have you used? What dose have you taken?
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Have you ever tried opioid addiction treatment? If yes, what strategies did you use, and what difficulties did you encounter?
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Are you presently taking any drugs, and do you have any co-occurring medical or mental health disorders (such as depression, anxiety, or PTSD)?
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">These are only a handful of the factors that offer us crucial details regarding the unique circumstances of each patient. We will consider any additional physical or mental health concerns you may be having in addition to your medical condition while designing a rehab program that is specific to you.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">A little word on the symptoms of opioid withdrawal: A lot of people who are addicted to opioids refuse treatment because they are afraid of the withdrawal symptoms. Such worries shouldn't prevent you from receiving the necessary and appropriate assistance. Our listed medical professionals from prominent rehabs and detox centers are skilled in helping you navigate the physical difficulties associated with opiate withdrawal safely and effectively.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      How Can You Tell Whether You Have an Opioid Addiction?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Addiction cannot be diagnosed with a blood test or other laboratory test, but there are telltale behavioral signs that the illness has taken hold. You're most likely looking at addiction if you spend all of your time recouping from the negative impacts of substance misuse after being fixated on obtaining and abusing drugs. Other red flags include losing sight of your morals, acting in a way that endangers you or others, and suffering from the effects of your use in your relationships and other spheres of life.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">If you're not sure how big of a problem you have, make a quick inventory to see how much your drug usage is interfering with your life.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      How Is Addiction to Opioids Handled?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Programs and services for treating heroin and opioid addiction differ depending on the provider as well as the kind and intensity of care required to handle your unique circumstance properly. These are some essential elements of effective treatment programs for opioid addiction.</p>

                    <ul className=" mb-5">
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Medical detox
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Assessments including medical, mental health, and substance abuse histories
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Mental health services
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Medical services
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Medication-assisted treatment
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Group and one-to-one chemical health services
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Wellness and fitness
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Twelve Step Facilitation
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Nutritional counseling
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Individualized planning
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Family services
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Spiritual care
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Educational and experiential workshops
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Post-rehab planning
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">It's also critical to understand that you won't progress through recovery at the same rate as those around you. Your recovery journey from opioid use disorder will be unique to you, depending on your needs, obstacles, and circumstances, even while there are similar milestones in the process. For you, one or more of the following rehab levels could be suggested:</p>

                    <ul className=" mb-5">
                      {/* <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Inpatient</b>—24/7 staffing and programming</div>
                        </div>
                      </li> */}
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Detoxification: </b>Detoxification is a medically supervised process used to safely manage opioid withdrawal symptoms, typically lasting a few days to a week, while maintaining patient comfort.</div>
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Residential/Inpatient Treatment: </b>Residential/inpatient treatment offers 24-hour structured care in facilities to address psychological, emotional, and behavioral aspects of addiction through therapy, group support, and individualized treatment plans.</div>
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Intensive Outpatient Program (IOP):</b>IOP provides structured therapy in outpatient treatment centers, aiming to help patients transition back into daily life while maintaining intensive support for recovery.</div>
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          <div><b>Outpatient Treatment:</b>Outpatient treatment involves providing ongoing support and therapy to patients, typically in clinics or treatment centers, over weeks or months, while allowing them to resume daily activities.</div>
                        </div>
                      </li>
                    </ul>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      How Long Does Opioid and Heroin Addiction Rehab Take?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Your unique demands will determine how long you take part in an opioid treatment program. Our experts will consult with you, your family, and your insurance company to create the best plan for you. Like high blood pressure or diabetes, addiction is a chronic illness. Learning to control your symptoms is the first step in regaining and maintaining your health. Initially, you will do this in a rehab setting with the structure and support of other people, but eventually, you will be responsible for your sobriety at home.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Current scientific studies on substance misuse recovery show that the strongest indicator of sustained abstinence is continued participation in recovery-oriented activities. Since there is a greater chance of recurrence during the first 18 months of treatment, active participation is especially crucial.
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      How Much Does Addiction Treatment for Heroin and Opiates Cost?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Depending on the kind of therapy, location, and duration of care, the cost of treating opiate and heroin addiction varies significantly. While residential or inpatient treatment can cost anywhere from $5,000 to $30,000 for a 30-day program, depending on the facility's features, detox programs usually cost between $1,000 and $5,000 for a brief stay. For a three-month treatment plan, outpatient programs, which provide greater flexibility, typically cost between $5,000 and $10,000. If medication-assisted therapy (MAT) is utilized, costs may also rise; monthly expenditures for MAT can range from $500 to $1,200. For many people seeking assistance, insurance, financial aid, and government programs can help pay or minimize these costs.
                    </p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      What Is the Most Effective Heroin Addiction Treatment?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      The best treatment for heroin addiction involves a multi-faceted approach combining medication-assisted treatment (MAT) with behavioral therapy. MAT reduces withdrawal symptoms, while behavioral therapies address psychological aspects. A structured environment, like inpatient rehab or IOP, ensures a holistic approach. Long-term support is crucial for maintaining sobriety.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      The dual approach to treatment addresses immediate physical symptoms, psychological triggers, and relapse prevention, offering a sustainable path to recovery when combined with long-term support. Similarly, selecting a 12-step program for the treatment of heroin addiction provides several important advantages that promote sustained recovery;
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          A disciplined framework with an emphasis on spiritual, social, and personal accountability.
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Emphasizes peer support and encouragement.
                        </div>
                      </li>
                      <li className="mb-1">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Growth of positive coping strategies and life skills.
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      The 12-step program aims to alleviate opioid withdrawal discomfort and decrease drug cravings, providing personalized care with multiple recovery pathways and promoting sustained treatment engagement for lifelong addiction healing and addiction recovery.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      To address the complicated nature of addiction and support long-term recovery, a 12-step program takes into account several factors. Among these variables are:
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Substances used
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Physical health
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Mental health
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Family relationships
                        </div>
                      </li>
                      <li className="mb-0">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <svg className="w-3 h-3 flex-shrink-0 mr-2" style={{ marginTop: "7px", transform: "rotate(0deg)" }} width={8} height={9} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.1713 1.29575L5.91721 3.85194C6.05236 3.99473 6.11886 4.15965 6.11886 4.30646C6.11886 4.45328 6.05182 4.63589 5.91775 4.76159L3.17184 7.31778C2.97542 7.50192 2.68036 7.55711 2.42358 7.45742C2.16679 7.35772 2 7.15829 2 6.88075V1.75028C2 1.49003 2.16692 1.25513 2.42368 1.15537C2.68045 1.05562 2.97608 1.11133 3.1713 1.29575Z" fill="#667085" />
                          </svg>
                          Gender
                        </div>
                      </li>
                    </ul>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      How Are Addiction and Withdrawal from Opioids Treated?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Medication-assisted therapy (MAT), which employs certain drugs to control withdrawal symptoms, lessen cravings, and avoid relapse, is used to treat opioid addiction and withdrawal.
                    </p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">
                      Stabilizing brain chemistry, lessening withdrawal pain, and promoting long-term recovery are the objectives.
                    </p>

                    <ul className=" mb-5">
                      <li className="mb-2">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <span className="mr-2">1.</span>
                          <div className="flex flex-col">
                            <b>Medications for Opiate Withdrawal:</b>
                            Opiate withdrawal medications include methadone, a long-acting opioid agonist, buprenorphine, a partial opioid agonist, and clonidine, which help reduce symptoms and cravings, stabilize brain opioid receptors, and reduce anxiety and muscle aches.
                          </div>
                        </div>
                      </li>
                      <li className="mb-2">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <span className="mr-2">2.</span>
                          <div className="flex flex-col">
                            <b>Medications for Long-Term Addiction Treatment:</b>
                            Long-term addiction treatment includes Naltrexone, an opioid antagonist, which prevents relapses by blocking opioid effects, reducing cravings, and aiding recovery. Methadone is used for maintenance, reducing cravings, and allowing normal functioning without opioid-associated highs and lows. Buprenorphine manages withdrawal and maintenance therapy, ensuring stability and preventing relapse in patients.
                          </div>
                        </div>
                      </li>
                      <li className="mb-2">
                        <div className="flex text-gray-500 dark:text-gray-300 font-normal text-md">
                          <span className="mr-2">3.</span>
                          <div className="flex flex-col">
                            <b>Extended-Release Naltrexone (injected every four weeks):</b>
                            Naltrexone, an opioid receptor antagonist, is injected every four weeks and has no euphoric effects, dependence, withdrawal symptoms, or breathing problems. It has been shown to reduce cravings and the likelihood of relapse. The extended-release formulation, Vivitrol, is injected and slowly released, preventing highs while opioids are in the system.
                          </div>
                        </div>
                      </li>
                    </ul>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Many treatment centers use methadone for opioid use disorder. Learn why some use Suboxone rather than Methadone for opioid use disorder.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Do you have questions about medication-assisted treatment for heroin, opioid, or opiate addiction? Read our FAQs on medication-assisted treatment.</p>

                    {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Opioid Addiction Treatment Facilities
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Hazelden Betty Ford opioid treatment programs and services are offered at the following locations: Rancho Mirage, California; Naples, Florida; Center City, Plymouth and St. Paul, Minnesota; Beaverton and Newberg, Oregon.</p> */}

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                    Can We Keep Narcan in Our House?
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Narcan (naloxone) is a life-saving medication that can reverse the effects of opioid overdose, making it an essential tool in preventing overdose deaths. It can be kept at home for emergency response, accessibility, peace of mind, and accessibility for others. In the event of an opioid overdose, Narcan can restore normal breathing and prevent death until emergency medical services arrive.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">It is also accessible for those managing opioid use or recovery, providing reassurance. Keeping Narcan at home can also be a preventive measure for friends or family members who use opioids. Proper training on how to use Narcan and its expiration date is essential. Narcan is often available without a prescription at pharmacies or community organizations offering training and free Narcan kits. Overall, keeping Narcan at home is a proactive step in safeguarding against the risks associated with opioid use.</p>

                    {/* <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Find more information and helpful resources about Naloxone, including Narcan, at <a className='text-blue-500 hiver:text-blue-800' href="">NIDA</a>.</p> */}

                    {/* <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facilis eum aliquam delectus deleniti corrupti nulla veniam cumque velit, veritatis eos quia dolorum enim ut quidem? Beatae officia aliquam voluptas!
                      Dolorum quia at maxime aliquam distinctio, molestias ratione nesciunt quod cupiditate laudantium aspernatur consequatur eaque vero id eius rerum libero dolore nobis modi. Dicta, illum architecto expedita explicabo laboriosam perferendis.
                      Debitis maiores pariatur enim voluptatum? Optio ipsa dignissimos, dolore, debitis adipisci veniam sed et labore, odio ab incidunt nihil officiis temporibus ut nemo aliquid ad eos rerum qui deserunt ipsam!</p> */}
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5} />}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default ResidentialTreatment