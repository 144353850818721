import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Alert from '../../../components/Alert';

import AdminSideBar from '../../../components/AdminSideBar';
import AdminHeader from '../../../components/AdminHeader';

import { Link, useNavigate } from "react-router-dom";
//import { checkRole, getCookie, base64_encode, base64_decode, openInNewTab, addScript, appendStylesheet, removeScript, removeStylesheet } from '../../../func';
import { checkRole, getCookie, base64_encode, base64_decode, openInNewTab, roleBasedBreadCrumbUrl } from '../../../func';

const AllListings = () => {
  const navigate = useNavigate();

  const [user_id, setUserID] = useState(0);
  //const [offset, setOffSet] = useState(0);
  //const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(true);

  const [alert, setAlert] = useState({
    type: '',
    message: '',
    show: false
  });

  const [allListings, setAllListing] = useState([]);
  // const [filterText, setFilterText] = useState('');
  // const [filteredListing, setFilteredListing] = useState([]);

  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 100
  });
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const maxPages = 3;

  useEffect(() => {
    // addScript("https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js", "jQueryScript", false, false, "anonymous");
    // addScript("https://cdn.datatables.net/1.13.6/js/jquery.dataTables.min.js", "DataTableScript", false, false, "anonymous");
    // appendStylesheet("https://cdn.datatables.net/1.13.6/css/jquery.dataTables.min.css", "DataTableStylesheet");

    const token = getCookie("token");

    if (token === null) {
      // navigate(`/admin/login`, { replace: true });
      navigate(`/login`, { replace: true });
    }
    else {
      if (checkRole(getCookie("token")) !== "admin")
        navigate(`/admin/add_listing`, { replace: true });
      else {
        const parts = base64_decode(token).split("|");
        setUserID(parts[0]);
        //fetchListing();
      }

      //fetchListingTestFunction();
    }

    // return () => {
    //   removeScript("jQueryScript");
    //   removeScript("DataTableScript");
    //   removeStylesheet("DataTableStylesheet");
    // }
  }, [])

  useEffect(() => {
    fetchListing();
  }, [controller]);

  // useEffect(() => {
  //   if (allListings.length > 10) {
  //     // $("#myListingTable").DataTable({
  //     //     data: this.props.names,
  //     //     //columns,
  //     //     ordering: false
  //     // });

  //     //$('#myListingTable').DataTable();
  //     window["bindDataTable"]("myListingTable");
  //   }
  // }, [allListings]);

  // useEffect(() => {
  //     setFilteredListing(allListings.filter(x=>x.title.includes(filterText)));
  // }, [filterText]);    

  const handleMessage = (params) => {
    setAlert({ type: params.type, message: params.message, show: true });

    setTimeout(() => {
      setAlert({ type: '', message: '', show: false });
    }, 3000);
  }

  // const fetchListingTestFunction = () => {
  //   const json = JSON.stringify({ "state": "AL", "city": "Wetumpka", "offset": 0, "limit": 10000 });
  //   //const json = JSON.stringify({ "state": "AL", "offset": 0, "limit": 10000 });
  //   //const json = JSON.stringify({ "offset": 0, "limit": 10000 });

  //   axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=get_listing`, JSON.stringify({ params: json }), {
  //     headers: {
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     }
  //   }).then((response) => {
  //     if (response.data.success === "true") {
  //       setAllListing(response.data.listing);
  //       //setFilteredListing(response.data.listing);
  //     }
  //     else {
  //       handleMessage({ type: 'danger', message: response.data.message, show: true });
  //     }
  //   }).catch((error) => {
  //     console.error(`Error: ${error}`);
  //   });
  // }

  const fetchListing = () => {
    const json = JSON.stringify({ "offset": (controller.page === 0 ? 0 : (controller.page * controller.rowsPerPage)), "limit": controller.rowsPerPage });
    const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_listing`;

    axios.post(url, JSON.stringify({ params: json }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      if (response.data.success === "true") {
        setAllListing(response.data.listing);
        setTotalPages(Math.ceil(response.data.total_listing / controller.rowsPerPage));

        setTimeout(() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }, 500)
      }
      else {
        handleMessage({ type: 'danger', message: response.data.message, show: true });
      }
      setLoading(false);
    }).catch((error) => {
      console.error(`Error: ${error}`);
    });
  }

  const deleteListing = (listing_id) => {
    const json = JSON.stringify({ "user_id": user_id, "listing_id": listing_id });

    axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=delete_listing`, JSON.stringify({ params: json }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      if (response.data.success === "true") {
        handleMessage({ type: 'success', message: response.data.message, show: true });
      }
      else {
        handleMessage({ type: 'danger', message: response.data.message, show: true });
      }
    }).catch((error) => {
      console.error(`Error: ${error}`);
    });
  }

  const createPagination = () => {
    return (
      <div className="flex justify-center items-center p-4">
        <div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 mr-4" onClick={() => handlePageChange(controller.page - 1)}>
          <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${controller.page === 0 ? 'text-gray-400' : 'text-blue-500'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}><path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" /></svg>
        </div>
        {
          (controller.page > 0 && totalPages > maxPages) && (<div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:text-white text-gray-500 dark:text-gray-300 hover:text-white text-blue-500 mr-4" onClick={() => handlePageChange(controller.page - 1)}>...</div>)
        }
        {
          [...Array(totalPages)].map((u, i) => (
            (controller.page === i)
              ?
              (<div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 bg-blue-500 text-white mr-4" key={i}>{i + 1}</div>)
              :
              ((i === (controller.page - 1) || i === (controller.page + 1)) && <div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:text-white text-gray-500 dark:text-gray-300 hover:text-white mr-4" onClick={() => handlePageChange(i)} key={i}>{i + 1}</div>)
          ))
        }
        {/* {
          (controller.page > 0 && controller.page >= maxPages && controller.page < totalPages - 1) && (<div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:text-white text-gray-500 dark:text-gray-300 hover:text-white mr-4" onClick={() => handlePageChange(controller.page + 1)}>...</div>)
        } */}
        {
          (controller.page >= 0 && controller.page < totalPages - 1 && totalPages > 3) && (<div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:text-white text-gray-500 dark:text-gray-300 hover:text-white text-blue-500 mr-4" onClick={() => handlePageChange(controller.page + 1)}>...</div>)
        }
        {/* <div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 bg-blue-500 text-white mr-4">1</div>
        <div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:text-white text-gray-500 dark:text-gray-300 hover:text-white mr-4">2</div> */}
        <div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 mr-4" onClick={() => handlePageChange(controller.page + 1)}>
          <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${controller.page === (totalPages - 1) ? `text-gray-400` : `text-blue-500`}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}><path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" /></svg>
        </div>
        <span>Total Pages: {totalPages}</span>
      </div>
    )
  }

  const handlePageChange = (newPage) => {
    //console.log('newpage', newPage);
    if (newPage >= 0 && newPage <= totalPages - 1) {
      //console.log('newpage in', newPage);
      setLoading(true);
      setCurrentPage(newPage);

      setController({
        ...controller,
        page: newPage
      });
    }
  };

  return (
    <>
      <AdminSideBar />
      <AdminHeader />

      <div className="dark:bg-background lg:ml-64 ml-0 mt-20 min-h-[calc(100vh-80px)] p-7">
        <section className="dark:bg-background">

          <div className="container mx-auto">

            <div className="flex justify-between items-center flex-wrap">
              <div className="flex text-gray-400 text-sm mb-4 items-center">
                {/* {
                  base64_decode(getCookie("token")).split("|")[3] === "admin"
                    ?
                    (<Link to={"/admin/dashboard"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                    :
                    (<Link to={"/admin/add_listing"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                } */}
                {
                  getCookie("token") !== null && (<Link to={roleBasedBreadCrumbUrl(base64_decode(getCookie("token")).split("|")[3])} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                }
                <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                </svg>
                <span>My Listing </span>
              </div>
            </div>
            <Alert type={alert.type} message={alert.message} show={alert.show} />

            <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg mb-7 overflow-hidden">
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">

                        <div className="mb-4">
                          <table id="myListingTable" className="min-w-full">
                            <thead>
                              <tr>
                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 dark:bg-background items-center font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '5%' }}>ID</th>
                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 dark:bg-background items-center font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '35%' }}>Name</th>
                                {/* <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '12.7441%' }}>
                                                                    <a href="#" className="dataTable-sorter">Category</a>
                                                                </th> */}
                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '17.8828%' }}>Location</th>
                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '11.7163%' }}>Ratings</th>
                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-4 py-3 text-left" data-sortable style={{ width: '11.7163%' }}>Reviews</th>
                                <th scope="col" className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-4 pb-3 text-left" data-sortable style={{ width: '13.9774%' }}>Action</th>
                              </tr>
                            </thead>
                            {!loading ?
                              (<tbody>
                                {allListings.length > 0 ? allListings.map((business, index) => (
                                  <tr className="align-middle hover:bg-gray-50 dark:hover:bg-background" key={index}>
                                    <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-regular text-gray-500 dark:text-gray-300 px-4 py-3">
                                      {`${business.listing_id}`}
                                    </td>
                                    <td className="border-b border-gray-200 dark:border-gray-900 text-sm font-medium text-gray-700 dark:text-gray-300 px-4 py-3">
                                      <div className="flex items-center">
                                        {(business.images !== "" && business.images !== undefined) && (<img className="w-20 h-20 object-cover rounded-lg mr-2 border border-white" src={`${process.env.REACT_APP_BASE_API}${JSON.parse(business.images)[0]["base64"]}`} alt="" />)}
                                        <div className="flex" style={{ flexDirection: "column" }}>
                                          <div className='text-blue-500 hover:text-blue-500' onClick={() => openInNewTab(`/details/${base64_encode(business.listing_id.toString())}/${business.alias}`)} style={{ cursor: "pointer" }}>{business.title}</div>
                                          <div className="text-gray-500 dark:text-gray-300">
                                            {business.address}
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-regular text-gray-500 dark:text-gray-300 px-4 py-3">
                                      {`${business.city}, ${business.state}`}{/* Manhattan, USA */}
                                    </td>
                                    <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-500 dark:text-gray-300 px-4 py-3">
                                      <div className="flex items-center" style={{ justifyContent: "space-around" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-2 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                        </svg>
                                        {business["rating"]}
                                      </div>
                                    </td>
                                    <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-500 dark:text-gray-300 px-4 py-3">
                                      <div className="items-center text-center">
                                        {business["reviews"]}
                                      </div>
                                    </td>
                                    <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-700 dark:text-gray-300 px-4 py-3">
                                      <div className="flex items-center">
                                        <button type="button" className="border mr-2 border-gray-200 hover:bg-blue-500 dark:hover:border-blue-500 hover:text-white text-gray-700 dark:text-gray-300 dark:border-gray-800 rounded-full w-8 h-8 flex justify-center items-center" onClick={() => openInNewTab(`/admin/add_review/${business.listing_id}`)} title={"Add Reviews"}>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                          </svg>
                                        </button>
                                        <button type="button" className="border mr-2 border-gray-200 hover:bg-blue-500 dark:hover:border-blue-500 hover:text-white text-gray-700 dark:text-gray-300 dark:border-gray-800 rounded-full w-8 h-8 flex justify-center items-center" onClick={() => openInNewTab(`/admin/edit_listing/${business.listing_id}`)} title={"Edit Listing"}>
                                          <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.1767 2.5903C14.964 1.80323 16.2424 1.80323 17.0296 2.5903L17.4107 2.97163C18.1979 3.75885 18.1979 5.03604 17.4107 5.82326L10.4705 12.7603C10.206 13.0279 9.86907 13.1854 9.50065 13.2987L6.64776 13.9568C6.47772 13.9946 6.30138 13.9442 6.17857 13.8214C6.05577 13.6986 6.00538 13.5223 6.04317 13.3522L6.70129 10.4994C6.78631 10.1309 6.97209 9.794 7.23975 9.50116L14.1767 2.5903ZM16.3148 3.30289C15.9212 2.90928 15.2852 2.90928 14.8915 3.30289L14.0476 4.1449L15.8551 5.95236L16.6959 5.10846C17.0895 4.7158 17.0895 4.07783 16.6959 3.68422L16.3148 3.30289ZM7.68374 10.7261L7.20511 12.7949L9.24559 12.3163C9.45657 12.2753 9.62661 12.1809 9.75886 12.0203L15.1435 6.66401L13.336 4.8575L7.9514 10.2411C7.81914 10.3734 7.72468 10.5434 7.68374 10.7261ZM8.54969 3.89299C8.82679 3.89299 9.05351 4.11845 9.05351 4.39681C9.05351 4.67518 8.82679 4.90064 8.54969 4.90064H4.51911C3.68434 4.90064 3.00764 5.57765 3.00764 6.4121V15.4809C3.00764 16.3153 3.68434 16.9924 4.51911 16.9924H13.5879C14.4224 16.9924 15.0994 16.3153 15.0994 15.4809V11.4503C15.0994 11.1732 15.3261 10.9465 15.6032 10.9465C15.8803 10.9465 16.107 11.1732 16.107 11.4503V15.4809C16.107 16.8727 14.9797 18 13.5879 18H4.51911C3.12793 18 2 16.8727 2 15.4809V6.4121C2 5.02092 3.12793 3.89299 4.51911 3.89299H8.54969Z" fill="currentColor" />
                                          </svg>
                                        </button>
                                        <button type="button" className="border mr-2 border-gray-200 hover:bg-blue-500 dark:hover:border-blue-500 hover:text-white text-gray-700 dark:text-gray-300 dark:border-gray-800 rounded-full w-8 h-8 flex justify-center items-center" onClick={() => deleteListing(business.listing_id)} title={"Delete Listing"}>
                                          <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.5 14.5C7.5 14.775 7.275 15 7 15C6.725 15 6.5 14.775 6.5 14.5V7.5C6.5 7.225 6.725 7 7 7C7.275 7 7.5 7.225 7.5 7.5V14.5ZM10.5 14.5C10.5 14.775 10.275 15 10 15C9.725 15 9.5 14.775 9.5 14.5V7.5C9.5 7.225 9.725 7 10 7C10.275 7 10.5 7.225 10.5 7.5V14.5ZM13.5 14.5C13.5 14.775 13.275 15 13 15C12.725 15 12.5 14.775 12.5 14.5V7.5C12.5 7.225 12.725 7 13 7C13.275 7 13.5 7.225 13.5 7.5V14.5ZM12.6906 2.705L13.5281 4H16.5C16.775 4 17 4.22375 17 4.5C17 4.77625 16.775 5 16.5 5H16V15.5C16 16.8813 14.8813 18 13.5 18H6.5C5.11937 18 4 16.8813 4 15.5V5H3.5C3.22387 5 3 4.77625 3 4.5C3 4.22375 3.22387 4 3.5 4H6.47187L7.28125 2.705C7.55625 2.26644 8.0375 2 8.55312 2H11.4469C11.9625 2 12.4438 2.26644 12.6906 2.705ZM7.65312 4H12.3469L11.8687 3.235C11.7781 3.08875 11.6187 3 11.4469 3H8.55312C8.38125 3 8.22187 3.08875 8.13125 3.235L7.65312 4ZM5 15.5C5 16.3281 5.67156 17 6.5 17H13.5C14.3281 17 15 16.3281 15 15.5V5H5V15.5Z" fill="currentColor" />
                                          </svg>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                )) :
                                  (<tr><td colSpan={5}><div className="text-center mt-4">No item in the list</div></td></tr>)
                                }
                              </tbody>)
                              :
                              (
                                <tbody>
                                  {
                                    [...Array(5)].map((u, index) => (<tr key={index}>
                                      <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                      <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                      <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                      <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                      <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                      <td><div className="w-full h-8 skeleton mt-2"></div></td>
                                    </tr>))
                                  }
                                </tbody>
                              )
                            }
                          </table>
                        </div>

                        {/* pagination  */}
                        {totalPages > 1 && createPagination()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </section>
      </div>
    </>
  )
}

export default AllListings