import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Alert from '../../../components/Alert';

import AdminSideBar from '../../../components/AdminSideBar';
import AdminHeader from '../../../components/AdminHeader';

import { Link, useNavigate } from "react-router-dom";
import { getCookie, base64_encode, base64_decode, openInNewTab, checkRole, roleBasedBreadCrumbUrl } from '../../../func';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from "draftjs-to-html";

let token = '';

// const toolbarOptions = {
//     options: ["inline", "fontSize", "image", "emoji"],
//     inline: {
//         options: ["bold", "italic", "underline", "strikethrough"],
//     },
// };

const toolbarOptions = {
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    fontSize: {
        // icon: fontSize,
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
    },
    embedded: {
        // icon: embedded,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        embedCallback: undefined,
        defaultSize: {
            height: 'auto',
            width: 'auto',
        },
    },
    image: {
        // icon: image,
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        urlEnabled: true,
        uploadEnabled: true,
        alignmentEnabled: true,
        uploadCallback: undefined,
        previewImage: false,
        inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        alt: { present: false, mandatory: false },
        defaultSize: {
            height: 'auto',
            width: 'auto',
        },
    }
    // history: { inDropdown: true },
}

const AddPost = () => {
    const navigate = useNavigate();

    const [postTitle, setPostTitle] = useState("");
    const [postCategory, setPostCategory] = useState("");
    const [postContent, setPostContent] = useState("");
    const [postTitleImage, setTitleImage] = useState([]);
    const [postStatus, setPostStatus] = useState("");

    const [user_id, setUserID] = useState(0);
    const [offset, setOffSet] = useState(0);
    const [limit, setLimit] = useState(0);
    const [loading, setLoading] = useState(true);

    const [myPosts, setMyPosts] = useState([]);

    const [alert, setAlert] = useState({
        type: '',
        message: '',
        show: false
    });

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const [controller, setController] = useState({
        page: 0,
        rowsPerPage: 100
    });
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const maxPages = 3;

    useEffect(() => {
        token = getCookie("token");

        if (token === null) {
            // navigate(`/admin/login`, { replace: true });
            navigate(`/login`, { replace: true });
        }
        else {
            if (getCookie("token") !== undefined && getCookie("token") !== null) {
                //user is already loggedin
                if (checkRole(getCookie("token")) === "admin" || checkRole(getCookie("token")) === "author") {
                    const parts = base64_decode(token).split("|");
                    setUserID(parts[0]);
                    //fetchPosts();

                    const fullURL = window.location.href;
                    //console.log(`The full URL is: ${fullURL}`);
                    if (fullURL.includes('/#all_posts')) {
                        const element = document.getElementById("all_posts");
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                    }
                }
                else
                    navigate(`/admin/add_listing`, { replace: true });
            }
        }
    }, []);

    useEffect(() => {
        fetchPosts();
    }, [controller]);

    useEffect(() => {
        //console.log("convertToRaw: ", convertToRaw(editorState.getCurrentContent()));
        //console.log("draftToHtml: ", draftToHtml(convertToRaw(editorState.getCurrentContent())));
        setPostContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    }, [editorState]);

    // useEffect(()=>{
    //     handleMessage({ type: 'danger', message: "Please provide listing title!", show: true });
    // },[]);

    const handleMessage = (params) => {
        setAlert({ type: params.type, message: params.message, show: true });

        setTimeout(() => {
            setAlert({ type: '', message: '', show: false });
        }, 3000);
    }

    const changeHandler = (e) => {
        let files = e.target.files;
        //console.log("files: ", files);

        var allFiles = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            let ext = '';
            switch (file.type) {
                case "image/x-png":
                    ext = "png"
                    break;
                case "image/png":
                    ext = "png"
                    break;
                case "image/jpeg":
                    ext = "jpg"
                    break;
                case "image/webp":
                    ext = "webp"
                    break;
                default:
                    break;
            }

            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let fileInfo = {
                    name: file.name.replace(`.${ext}`, ''),
                    type: file.type,
                    extention: ext,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file: file,
                };

                allFiles.push(fileInfo);

                if (allFiles.length == files.length) {
                    console.log(allFiles);
                    //if (this.props.multiple) this.props.onDone(allFiles);
                    //else this.props.onDone(allFiles[0]);
                    setTitleImage(allFiles);
                }
            }
        }
    }

    const removeImage = (i) => {
        let array = [...postTitleImage];
        array.splice(i, 1);
        setTitleImage(array);
    }

    const fetchPosts = () => {
        //const json = JSON.stringify({ "token": token, "offset": 0, "limit": 10000 });
        const json = JSON.stringify({ "token": token, "offset": (controller.page === 0 ? 0 : (controller.page * controller.rowsPerPage)), "limit": controller.rowsPerPage });

        const url = `${process.env.REACT_APP_BASE_API}/api.php?action=get_all_post`; //development

        axios.post(url, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                setMyPosts(response.data.posts);
                //setFilteredListing(response.data.listing);
                setTotalPages(Math.ceil(response.data.total_posts / controller.rowsPerPage));
                setLoading(false);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
            setLoading(false);
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const deletePost = (post_id) => {
        const json = JSON.stringify({ "token": token, "postid": post_id });

        axios.post(`${process.env.REACT_APP_BASE_API}/api.php?action=delete_post`, JSON.stringify({ params: json }), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            if (response.data.success === "true") {
                handleMessage({ type: 'success', message: response.data.message, show: true });
                setMyPosts(response.data.posts);
            }
            else {
                handleMessage({ type: 'danger', message: response.data.message, show: true });
            }
        }).catch((error) => {
            console.error(`Error: ${error}`);
        });
    }

    const addPost = () => {
        //console.log(`${postTitle}, ${postCategory}, ${postContent}, ${postStatus}`);
        if (postTitle.trim() !== "" && postCategory.trim() !== "" && postContent.trim() !== "" && postTitleImage.length > 0 && postStatus !== "") {

            const json = JSON.stringify({ "posttitle": postTitle.trim(), "category": postCategory.trim(), "postcontent": postContent.trim(), "token": token, "titleimage": postTitleImage, "status": postStatus.trim() });
            const url = `${process.env.REACT_APP_BASE_API}/api.php?action=add_post`; //development

            axios.post(url, JSON.stringify({ params: json }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.data.success === "true") {
                    setMyPosts(response.data.posts);
                    handleMessage({ type: 'success', message: response.data.message, show: true });
                    cancelPost();
                }
                else {
                    handleMessage({ type: 'danger', message: response.data.message, show: true });
                }
                setLoading(false);
            }).catch((error) => {
                console.error(`Error: ${error}`);
            });

        }
        else {
            if (postTitle.trim() === "" && postCategory.trim() === "" && postContent.trim() === "" && postTitleImage.length === 0 && postStatus === "")
                handleMessage({ type: 'danger', message: "Please provide post title, category, content, title image and post status!", show: true });
            else if (postTitle.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide post title!", show: true });
            else if (postCategory.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide post category!", show: true });
            else if (postContent.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide post content!", show: true });
            else if (postTitleImage.length === 0)
                handleMessage({ type: 'danger', message: "Please provide post title image!", show: true });
            else if (postStatus.trim() === "")
                handleMessage({ type: 'danger', message: "Please provide post status!", show: true });
        }
    }

    const cancelPost = () => {
        setPostTitle('');
        setPostCategory('');
        setPostContent('');
        setTitleImage([]);
        setPostStatus('');
    }

    const createBlogsTable = () => {
        return (
            <section className="dark:bg-background" id="all_posts">
                <div className="container mx-auto">
                    <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg mb-7 overflow-hidden">

                        <div className="flex justify-between items-center mb-4 px-6 pt-6">
                            <h4 className="text-gray-700 dark:text-gray-300 text-lg font-semibold">
                                All Posts
                            </h4>
                        </div>

                        <div className="flex flex-col">
                            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                    <div className="overflow-hidden">
                                        <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">

                                            <div className="dataTable-container">
                                                <table id="myTable" className="min-w-full dataTable-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 dark:bg-background items-center flex font-medium text-gray-700 dark:text-gray-100 px-6 py-3 text-left" style={{ width: '60%' }}>Title</th>
                                                            <th className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-6 py-3 text-left" style={{ width: '15%' }}>Category</th>
                                                            <th className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-6 py-3 text-left" style={{ width: '10%' }}>Status</th>
                                                            <th className="text-base bg-gray-100 border-b border-gray-200 dark:border-gray-900 whitespace-nowrap dark:bg-background font-medium text-gray-700 dark:text-gray-100 px-6 pb-3 text-left" style={{ width: '15%' }}>Actions</th>
                                                        </tr>
                                                    </thead>

                                                    {!loading ?
                                                        (<tbody>

                                                            {(myPosts?.length > 0) ? myPosts.map((post, index) => (<tr className="align-middle hover:bg-gray-50 dark:hover:bg-background" key={index}>
                                                                <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-700 dark:text-gray-300 px-6 py-3">
                                                                    <div className="flex items-center mr-10">
                                                                        <img className="w-10 h-10 object-cover rounded-lg mr-4 border border-white" src={`${process.env.REACT_APP_BASE_API}${post.post_picture}`} alt={`${post.post_title} title img`} />
                                                                        <Link className='hover:text-blue-500 text-gray-700 dark:text-gray-100' to={`/post/${base64_encode(post.post_id.toString())}/${post.post_slug}`} target='_blank'>{post.post_title}</Link>
                                                                    </div>
                                                                </td>
                                                                <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-regular text-gray-500 dark:text-gray-300 px-6 py-3">{post.category}</td>
                                                                <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-regular text-gray-500 dark:text-gray-300 px-6 py-3">{post.post_status}</td>
                                                                <td className="border-b border-gray-200 dark:border-gray-900 whitespace-nowrap text-sm font-medium text-gray-700 dark:text-gray-300 px-6 py-3">
                                                                    <div className="flex items-center">
                                                                        <button type="button" className="border mr-2 border-gray-200 hover:bg-blue-500 dark:hover:border-blue-500 hover:text-white text-gray-700 dark:text-gray-300 dark:border-gray-800 rounded-full w-8 h-8 flex justify-center items-center" onClick={() => openInNewTab(`/admin/edit_post/${base64_encode(post.post_id.toString())}`)}>
                                                                            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M14.1767 2.5903C14.964 1.80323 16.2424 1.80323 17.0296 2.5903L17.4107 2.97163C18.1979 3.75885 18.1979 5.03604 17.4107 5.82326L10.4705 12.7603C10.206 13.0279 9.86907 13.1854 9.50065 13.2987L6.64776 13.9568C6.47772 13.9946 6.30138 13.9442 6.17857 13.8214C6.05577 13.6986 6.00538 13.5223 6.04317 13.3522L6.70129 10.4994C6.78631 10.1309 6.97209 9.794 7.23975 9.50116L14.1767 2.5903ZM16.3148 3.30289C15.9212 2.90928 15.2852 2.90928 14.8915 3.30289L14.0476 4.1449L15.8551 5.95236L16.6959 5.10846C17.0895 4.7158 17.0895 4.07783 16.6959 3.68422L16.3148 3.30289ZM7.68374 10.7261L7.20511 12.7949L9.24559 12.3163C9.45657 12.2753 9.62661 12.1809 9.75886 12.0203L15.1435 6.66401L13.336 4.8575L7.9514 10.2411C7.81914 10.3734 7.72468 10.5434 7.68374 10.7261ZM8.54969 3.89299C8.82679 3.89299 9.05351 4.11845 9.05351 4.39681C9.05351 4.67518 8.82679 4.90064 8.54969 4.90064H4.51911C3.68434 4.90064 3.00764 5.57765 3.00764 6.4121V15.4809C3.00764 16.3153 3.68434 16.9924 4.51911 16.9924H13.5879C14.4224 16.9924 15.0994 16.3153 15.0994 15.4809V11.4503C15.0994 11.1732 15.3261 10.9465 15.6032 10.9465C15.8803 10.9465 16.107 11.1732 16.107 11.4503V15.4809C16.107 16.8727 14.9797 18 13.5879 18H4.51911C3.12793 18 2 16.8727 2 15.4809V6.4121C2 5.02092 3.12793 3.89299 4.51911 3.89299H8.54969Z" fill="currentColor" />
                                                                            </svg>
                                                                        </button>
                                                                        <button type="button" className="border mr-2 border-gray-200 hover:bg-blue-500 dark:hover:border-blue-500 hover:text-white text-gray-700 dark:text-gray-300 dark:border-gray-800 rounded-full w-8 h-8 flex justify-center items-center" onClick={() => deletePost(post.post_id)}>
                                                                            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M7.5 14.5C7.5 14.775 7.275 15 7 15C6.725 15 6.5 14.775 6.5 14.5V7.5C6.5 7.225 6.725 7 7 7C7.275 7 7.5 7.225 7.5 7.5V14.5ZM10.5 14.5C10.5 14.775 10.275 15 10 15C9.725 15 9.5 14.775 9.5 14.5V7.5C9.5 7.225 9.725 7 10 7C10.275 7 10.5 7.225 10.5 7.5V14.5ZM13.5 14.5C13.5 14.775 13.275 15 13 15C12.725 15 12.5 14.775 12.5 14.5V7.5C12.5 7.225 12.725 7 13 7C13.275 7 13.5 7.225 13.5 7.5V14.5ZM12.6906 2.705L13.5281 4H16.5C16.775 4 17 4.22375 17 4.5C17 4.77625 16.775 5 16.5 5H16V15.5C16 16.8813 14.8813 18 13.5 18H6.5C5.11937 18 4 16.8813 4 15.5V5H3.5C3.22387 5 3 4.77625 3 4.5C3 4.22375 3.22387 4 3.5 4H6.47187L7.28125 2.705C7.55625 2.26644 8.0375 2 8.55312 2H11.4469C11.9625 2 12.4438 2.26644 12.6906 2.705ZM7.65312 4H12.3469L11.8687 3.235C11.7781 3.08875 11.6187 3 11.4469 3H8.55312C8.38125 3 8.22187 3.08875 8.13125 3.235L7.65312 4ZM5 15.5C5 16.3281 5.67156 17 6.5 17H13.5C14.3281 17 15 16.3281 15 15.5V5H5V15.5Z" fill="currentColor" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>)) :
                                                                (<tr><td colSpan={4}><div className="text-center mt-4">No post added yet</div></td></tr>)
                                                            }
                                                        </tbody>)
                                                        :
                                                        (<tbody>
                                                            {
                                                                [...Array(5)].map((u, index) => (<tr key={index}>
                                                                    <td><div className="w-full h-8 skeleton mt-2 mx-2"></div></td>
                                                                    <td><div className="w-full h-8 skeleton mt-2 mx-2"></div></td>
                                                                    <td><div className="w-full h-8 skeleton mt-2 mx-2"></div></td>
                                                                    <td><div className="w-full h-8 skeleton mt-2 mx-2"></div></td>
                                                                </tr>))
                                                            }
                                                        </tbody>)
                                                    }
                                                </table>
                                            </div>

                                            {/* pagination  */}
                                            {totalPages > 1 && createPagination()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const createPagination = () => {
        return (
            <div className="flex justify-center items-center p-4">
                <div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 mr-4" onClick={() => handlePageChange(controller.page - 1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${controller.page === 0 ? 'text-gray-400' : 'text-blue-500'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}><path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" /></svg>
                </div>
                {
                    (controller.page > 0 && totalPages > maxPages) && (<div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:text-white text-gray-500 dark:text-gray-300 hover:text-white text-blue-500 mr-4" onClick={() => handlePageChange(controller.page - 1)}>...</div>)
                }
                {
                    [...Array(totalPages)].map((u, i) => (
                        (controller.page === i)
                            ?
                            (<div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 bg-blue-500 text-white mr-4" key={i}>{i + 1}</div>)
                            :
                            ((i === (controller.page - 1) || i === (controller.page + 1)) && <div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:text-white text-gray-500 dark:text-gray-300 hover:text-white mr-4" onClick={() => handlePageChange(i)} key={i}>{i + 1}</div>)
                    ))
                }
                {/* {
              (controller.page > 0 && controller.page >= maxPages && controller.page < totalPages - 1) && (<div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:text-white text-gray-500 dark:text-gray-300 hover:text-white mr-4" onClick={() => handlePageChange(controller.page + 1)}>...</div>)
            } */}
                {
                    (controller.page >= 0 && controller.page < totalPages - 1 && totalPages > 3) && (<div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:text-white text-gray-500 dark:text-gray-300 hover:text-white text-blue-500 mr-4" onClick={() => handlePageChange(controller.page + 1)}>...</div>)
                }
                {/* <div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 bg-blue-500 text-white mr-4">1</div>
            <div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 hover:bg-blue-500 dark:hover:bg-blue-500 dark:hover:text-white text-gray-500 dark:text-gray-300 hover:text-white mr-4">2</div> */}
                <div className="rounded-md w-8 h-8 flex justify-center items-center border border-gray-200 mr-4" onClick={() => handlePageChange(controller.page + 1)}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-4 w-4 ${controller.page === (totalPages - 1) ? `text-gray-400` : `text-blue-500`}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}><path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" /></svg>
                </div>
                <span>Total Pages: {totalPages}</span>
            </div>
        )
    }

    const handlePageChange = (newPage) => {
        //console.log('newpage', newPage);
        if (newPage >= 0 && newPage <= totalPages - 1) {
            //console.log('newpage in', newPage);
            setLoading(true);
            setCurrentPage(newPage);

            setController({
                ...controller,
                page: newPage
            });
        }
    };

    return (
        <>
            <AdminSideBar />
            <AdminHeader />

            <div className="dark:bg-background lg:ml-64 ml-0 mt-20 min-h-[calc(100vh-80px)] p-7">

                <section className="dark:bg-background">
                    <div className="container mx-auto">
                        {/* <div className="flex text-gray-400 text-sm items-center mb-4">
                            <a href="#" className="font-medium text-gray-700 dark:text-gray-200">Dashboard</a>
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                            <span>Add New Post</span>
                        </div> */}
                        <div className="flex text-gray-400 text-sm items-center mb-4">
                            {/* {
                                base64_decode(getCookie("token")).split("|")[3] === "admin"
                                    ?
                                    (<Link to={"/admin/dashboard"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                                    :
                                    (<Link to={"/admin/add_listing"} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            } */}
                            {
                                getCookie("token") !== null && (<Link to={roleBasedBreadCrumbUrl(base64_decode(getCookie("token")).split("|")[3])} className="font-medium text-gray-700 dark:text-gray-200">Dashboard</Link>)
                            }
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                            </svg>
                            <span>Add Post</span>
                        </div>

                        <Alert type={alert.type} message={alert.message} show={alert.show} />

                        <div className="bg-white shadow-front-2 dark:bg-foreground rounded-lg p-6 mb-7">
                            <h4 className="text-gray-700 dark:text-gray-300 text-xl font-semibold mb-3">Write Your Post</h4>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mb-4">
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Post Title <sup className="text-red-500">*</sup></label>
                                    <input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="Type a post title" value={postTitle} onChange={(e) => setPostTitle(e.target.value)} />
                                </div>
                            </div>

                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mb-4">
                                {/* <div className="lg:col-span-2 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Address <sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. Bloosom" defaultValue /></div>
                                <div className="lg:col-span-1 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Road or Street <sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" defaultValue /></div>
                                <div className="lg:col-span-1 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">City<sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" defaultValue /></div> */}
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Category<sup className="text-red-500">*</sup></label>
                                    <div className="relative">
                                        <select name="state" className="bg-white w-full border border-gray-200 dark:bg-foreground dark:border-gray-800 relative appearance-none rounded-lg px-4 py-[0.969rem] focus:outline-blue-300 text-gray-500 focus:outline-none" value={postCategory} onChange={(e) => setPostCategory(e.target.value)}>
                                            <option value={-1}>Select Category</option>
                                            <option value="education">Education</option>
                                            <option value="events">Events</option>
                                            <option value="news">News</option>
                                        </select>
                                        <svg width={10} height={6} className="w-3 h-3 text-gray-400 absolute right-3 top-1/2 -translate-y-1/2 z-10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74713 0.525274 9.25335 0.525274 9.56583 0.826613C9.87832 1.12795 9.87832 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mb-4">
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Content <sup className="text-red-500">*</sup></label>
                                    {/* <textarea className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" rows={10} placeholder="Write your content here....." value={postContent} onChange={(e) => setPostContent(e.target.value)} /> */}
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={setEditorState}
                                        editorClassName="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0 mh-500"
                                        placeholder="Write your content here....."
                                        toolbar={toolbarOptions}
                                    />
                                </div>
                            </div>

                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mb-4">
                                <div className="col-span-2">
                                    <div>
                                        <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Title Images <sup className="text-red-500">*</sup></label>
                                        <label htmlFor="upload-img" className="flex flex-col justify-center items-center cursor-pointer bg-gray-100 dark:bg-background dark:border-gray-800 border border-gray-200 rounded-lg py-10">
                                            <svg className="mb-2" width={43} height={42} viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_780_19100)">
                                                    <path d="M9.95 35C4.73084 35 0.5 30.9688 0.5 26C0.5 22.075 3.13616 18.7375 6.81312 17.5063C6.80459 17.3375 6.8 17.1687 6.8 17C6.8 11.475 11.4987 7 17.3 7C21.1916 7 24.5844 9.01562 26.4022 12.0125C27.3997 11.3187 28.6072 11 29.9 11C33.3781 11 36.2 13.6312 36.2 17C36.2 17.7625 36.0491 18.4875 35.78 19.1625C39.6125 19.9 42.5 23.1313 42.5 27C42.5 31.4188 38.7397 35 34.1 35H9.95ZM15.1344 21.4375C14.5241 22.025 14.5241 22.975 15.1344 23.5063C15.7512 24.1438 16.7487 24.1438 17.3066 23.5063L19.925 21.1187V29.5C19.925 30.3312 20.6272 31 21.5 31C22.3728 31 23.075 30.3312 23.075 29.5V21.1187L25.6344 23.5063C26.2512 24.1438 27.2487 24.1438 27.8066 23.5063C28.4759 22.975 28.4759 22.025 27.8066 21.4375L22.5566 16.4375C21.9987 15.8562 21.0012 15.8562 20.3844 16.4375L15.1344 21.4375Z" fill="#98A2B3" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_780_19100">
                                                        <rect width={42} height={42} fill="white" transform="translate(0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <h6 className="text-base font-medium text-gray-700 dark:text-gray-400 mb-4">Drag and Drop Image</h6>
                                            <div className="text-base text-gray-700 dark:text-gray-400 mb-4">OR</div>
                                            <div className="bg-blue-500 text-white p-4 rounded-lg">Browse Image</div>
                                            <input type="file" id="upload-img" className="hidden" onChange={changeHandler} accept="image/x-png,image/png,image/jpeg" />
                                        </label>
                                    </div>
                                    <div style={{ display: "flex", overflow: "overlay", paddingBottom: "30px", paddingLeft: "8px" }}>
                                        {postTitleImage.map((image, index) => (
                                            <div className="mt-2 p-4 mr-2 card" style={{ display: "flex", alignItems: "center", minHeight: "100px" }} key={index}>
                                                <img style={{ maxWidth: "120px" }} src={image["base64"]} />
                                                <i className="fa fa-window-close ml-2" style={{ color: "red", marginBottom: "80px" }} onClick={() => removeImage(index)}></i>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 mb-4">
                                {/* <div className="lg:col-span-2 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Address <sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. Bloosom" defaultValue /></div>
                                <div className="lg:col-span-1 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Road or Street <sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" defaultValue /></div>
                                <div className="lg:col-span-1 col-span-2"><label className="text-base text-gray-700 dark:text-gray-200 block mb-3">City<sup className="text-red-500">*</sup></label><input type="text" className="border border-gray-200 dark:border-gray-800 dark:text-gray-300 rounded-lg px-4 dark:bg-foreground py-[15px] shadow-input hover:border hover:border-blue-200 focus:border-blue-200 focus:outline-none w-full mb-0" placeholder="e.g. 12 San Pedro Street" defaultValue /></div> */}
                                <div className="col-span-2">
                                    <label className="text-base text-gray-700 dark:text-gray-200 block mb-3">Status<sup className="text-red-500">*</sup></label>
                                    <div className="relative">
                                        <select name="state" className="bg-white w-full border border-gray-200 dark:bg-foreground dark:border-gray-800 relative appearance-none rounded-lg px-4 py-[0.969rem] focus:outline-blue-300 text-gray-500 focus:outline-none" value={postStatus} onChange={(e) => setPostStatus(e.target.value)}>
                                            <option value={-1}>Select Status</option>
                                            <option value={0}>Pending</option>
                                            <option value={1}>Publish</option>
                                        </select>
                                        <svg width={10} height={6} className="w-3 h-3 text-gray-400 absolute right-3 top-1/2 -translate-y-1/2 z-10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74713 0.525274 9.25335 0.525274 9.56583 0.826613C9.87832 1.12795 9.87832 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="flex flex-wrap sm:justify-start justify-center">
                            <button type="button" className="px-6 py-5 bg-blue-500 hover:bg-blue-600 shadow-glow-blue text-white rounded-lg mr-4 mb-4" onClick={() => addPost()}>Add Post</button>
                            <button type="button" className="px-6 py-[17px] mb-4 border border-gray-200 dark:border-gray-800 text-gray-500 dark:text-gray-200 rounded-lg mr-4" onClick={() => cancelPost()}>Cancel</button>
                        </div>
                    </div>
                </section >

                {createBlogsTable()}
            </div >
        </>
    )
}

export default AddPost