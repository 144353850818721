import React from 'react'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PageContentContainer from '../../components/PageContentContainer';

import ScrollToTopButton from '../../components/ScrollToTopButton';

// import businesses_list from "../../../business";
import FeaturedListing from '../../components/FeaturedListing';
import RehabSearchForm from '../../components/RehabSearchForm';

import reviews from '../../components/ClientsSaysAboutUs/reviews.json'

const OpioidAddictionStories = () => {

  const CreateStories = () => {
    return reviews.map((review, index) => (
      <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden" key={index}>
        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
        <div className="flex items-center mb-9">
          <img className="w-20 h-20 border-2 border-white rounded-lg mr-6" src={review.image} alt="" />
          <div>
            <h5 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1"> {review.name} </h5>
            {/* <p className="text-sm text-gray-500 dark:text-gray-400"> Australia </p> */}
          </div>
        </div>
        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">{review.review}</p>
        <div className="flex justify-end mt-5">
          <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
          </svg>
        </div>
      </div>
    ))
  }

  return (
    <>
      <Header />

      <PageContentContainer>

        <>
          <section className="dark:bg-background mb-12">
            <div className="relative">
              <img className="w-full object-cover h-52" src="/img/cover-2.png" alt="" />
              <div className="absolute top-1/2 w-full -translate-y-1/2 text-center mb-4">
                <h1 className="capitalize text-4xl text-white font-semibold">Opioid Addiction Stories</h1>
                {/* <p className="text-white mt-2">Lorem ipsum dolor sit, amet consectetur adipisicing elit.</p> */}
              </div>
            </div>
          </section>

          <div className="container mx-auto px-5">
            <div className="grid grid-cols-3 gap-7 pb-10">

              <div className="lg:col-span-2 col-span-3">

                <div className='grid grid-cols-2 gap-7 pb-10'>
                  {reviews.length > 0 && <CreateStories />}
                </div>

                {/* <div className="rounded-lg shadow-front-2 dark:bg-foreground mb-7">
                  <img className="w-full rounded" src="img/Image/landing-page-image/building.jpg" alt="" />
                  <div className="p-6 border-b border-gray-200 dark:border-gray-800">
                    <h5 className="text-gray-700 lg:text-2xl text-lg font-semibold dark:text-gray-100 mb-6">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h5>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur quo perspiciatis deleniti debitis aspernatur eos voluptatibus veritatis, labore excepturi similique nisi quod repudiandae aliquid laborum fuga quos pariatur delectus quae.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi voluptates accusantium molestias magnam beatae modi dolores corrupti aliquam saepe fugit, vero repudiandae praesentium eum iste quia numquam ipsum, reiciendis consectetur!
                      Illo velit distinctio magnam corrupti quasi vero reprehenderit. Ab assumenda enim ipsam quia velit facere, maxime cumque eveniet eligendi ea officiis! Optio totam sit animi hic dolorum obcaecati illum asperiores.
                      Impedit deserunt illo accusantium? Nobis ex modi cum hic sed distinctio possimus placeat quos nam aut. Laborum obcaecati quis, rem exercitationem eum aliquam non? At magnam placeat molestias cum tempora!
                      Rerum sint praesentium voluptas deserunt officia nobis, porro recusandae necessitatibus omnis vero beatae perspiciatis expedita aperiam reiciendis eaque nostrum quis consequuntur iste eum ad sequi corrupti! Explicabo nobis sit praesentium.</p>

                    <h4 className="text-lg text-gray-700 dark:text-gray-100 font-medium mb-5">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit
                    </h4>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam vel ea sunt molestias facere quidem! Unde, repellendus dolores neque voluptatibus id, sunt impedit, nobis molestiae quisquam aut aliquid rem sed.
                      Doloremque, voluptatum voluptas iure facere, laudantium molestiae sint quam corporis illum ad amet recusandae vitae ea, accusamus dicta! Expedita accusantium quo reprehenderit! Repellendus quod eos tenetur nesciunt deleniti, cumque enim.</p>

                    <p className="text-gray-500 text-base dark:text-gray-300 mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda facilis eum aliquam delectus deleniti corrupti nulla veniam cumque velit, veritatis eos quia dolorum enim ut quidem? Beatae officia aliquam voluptas!
                      Dolorum quia at maxime aliquam distinctio, molestias ratione nesciunt quod cupiditate laudantium aspernatur consequatur eaque vero id eius rerum libero dolore nobis modi. Dicta, illum architecto expedita explicabo laboriosam perferendis.
                      Debitis maiores pariatur enim voluptatum? Optio ipsa dignissimos, dolore, debitis adipisci veniam sed et labore, odio ab incidunt nihil officiis temporibus ut nemo aliquid ad eos rerum qui deserunt ipsam!</p>
                  </div>
                </div> */}

              </div>

              <div className="lg:col-span-1 col-span-3">
                <RehabSearchForm />

                {/* {featuredListing()} */}
                {<FeaturedListing items={5} />}

              </div>

            </div>
          </div>

        </>

        <ScrollToTopButton />

        <Footer />
      </PageContentContainer>

    </>
  )
}

export default OpioidAddictionStories