import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { eraseCookie } from '../../func';

const AdminHeader = () => {
    const navigate = useNavigate();

    const logout = () => {
        setTimeout(() => {
            localStorage.removeItem("isLoggedIn");
            eraseCookie("token");
            // navigate(`/admin/login`, { replace: true });
            navigate(`/login`, { replace: true });
        }, 500);
    }

    const redirectToAddListing = () => {
        setTimeout(() => {
            // navigate(`/admin/add_listing`, { replace: true });
            navigate(`/admin/add_listing`, { replace: false });
        }, 100);
    }

    return (
        <div className="fixed lg:left-64 left-0 z-30 shadow-front-2 bg-white h-20 lg:w-[calc(100%-256px)] w-full px-7 py-4 flex flex-col justify-center dark:bg-foreground dark:text-gray-200 top-0" style={{}}>
            <div className="flex justify-between">
                <div className="flex items-center">
                    <button type="button" className="mr-3 lg:hidden block dashboard-header-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
                <div className="flex items-center">
                    <div className="dropdown mr-3" data-dropdown>
                        <button className="link hover:text-blue-500 flex items-center" data-dropdown-button>
                            <div className="relative lg:mr-3 mr-0">
                                <img className="w-8 h-8 rounded-full border border-white" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAMFBMVEX////S19vR1try8vIAAADQ1dnZ3eDf4uXl5+rW2t75+vrt7/D19vfn6uzx8vQYGShNh6tnAAAA5UlEQVQ4jd2SSQLDIAhFhQZn7f1vWzWolHTYdFVW4ecxijG/t1qcta7Ud/+jo2GIRC6+IjwhEPjiQ/tA8lciEACdFart30ETgE3N08uNQVA5GoEid4DmP+XxLUokMcZhF0RM7ARQVIhUTgGdRoQykgDajdiBAE0/XZHASJrNsi/ajRw1G3aMiKfJjMxmuDCWjejajADsGQM8I1xoNzeT7ELcrhhp5l3tVo5ZM21hTaCRtAS17q1k3cpeVFBp5cOWcQyw1ltpHINYVB+BIEmhXbJ8s7GrYpT5y/H+j92+mjma3Y9P9gDGnQaRgDV0MgAAAABJRU5ErkJggg==" data-dropdown-button alt="" />
                                <div className="w-2 h-2 bg-green-500 rounded-full border absolute bottom-0 right-0" />
                            </div>
                            <span data-dropdown-button className="lg:block hidden">My Account</span>
                            <svg className="w-3 h-auto ml-2" width={10} height={6} data-dropdown-button viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.99982 6.00001C4.79513 6.00001 4.59034 5.92467 4.43435 5.774L0.434558 1.91686C0.122074 1.61552 0.122074 1.12735 0.434558 0.826011C0.747042 0.524671 1.25327 0.524671 1.56575 0.826011L4.99982 4.13893L8.43464 0.826613C8.74712 0.525274 9.25335 0.525274 9.56583 0.826613C9.87831 1.12795 9.87831 1.61612 9.56583 1.91746L5.56604 5.7746C5.4098 5.92527 5.20481 6.00001 4.99982 6.00001Z" fill="currentColor" />
                            </svg>
                        </button>
                        <div className="dropdown-menu bg-white dark:bg-foreground dark:text-gray-300 text-gray-700">
                            <div>
                                <div className="dropdown-links max-w-xs w-48 rounded-md py-3">
                                    {/* <a href="listing-list.html" className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300"><svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" /></svg>Account
                                    </a>
                                    <a href="listing-list.html" className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                        Order Tracking
                                    </a>
                                    <a href="listing-list.html" className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
                                        </svg>
                                        My Voucher
                                    </a>
                                    <a href="listing-list.html" className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                        </svg>
                                        My Wishlist
                                    </a>
                                    <a href="listing-list.html" className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                                        </svg>
                                        Settings
                                    </a> */}
                                    <Link to={`/admin/profile`} className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                                        </svg> */}
                                        <svg className="w-4 h-4 mr-2" width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.8296 11.6H8.57036C5.49461 11.6 3 13.988 3 16.9331C3 17.5221 3.49886 18 4.11407 18H16.2866C16.9018 18.0009 17.4 17.5239 17.4 16.9331C17.4 13.988 14.9057 11.6 11.8296 11.6Z" fill="currentColor" />
                                            <path d="M14.2 6C14.2 8.20906 12.4094 10 10.2 10C7.99064 10 6.20001 8.20937 6.20001 6C6.20001 3.79063 7.99095 2 10.2 2C12.4094 2 14.2 3.79094 14.2 6Z" fill="#D0D5DD" />
                                        </svg>
                                        My Profile
                                    </Link>
                                    <Link to={`/admin/change_password`} className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300">
                                        <svg className="mr-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                                        </svg>
                                        Change Password
                                    </Link>
                                    <div className="link hover:text-blue-500 px-4 py-1 flex items-center text-sm text-gray-700 dark:text-gray-300" style={{ cursor: "pointer" }} onClick={() => logout()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                        </svg>
                                        Sign Out
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="inline-flex items-center px-3 py-2 rounded-lg border border-gray-200 text-gray-700 dark:text-gray-300" onClick={() => redirectToAddListing()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 lg:mr-2 mr-0" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" /></svg><span className="lg:flex hidden">Add Listing</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AdminHeader