import React, { useState, useEffect, useRef } from 'react'

import './style.css'
import reviews from './reviews.json'; //'../../components/ClientsSaysAboutUs/reviews.json'

// const reviews = [
//     {
//         "name": "Jacob H",
//         "review": "I was able to focus solely on my recovery since the environment was well-organized and the staff was really helpful. My life was changed by the residents' strong sense of community and the therapy sessions, which made me feel understood and never alone. The incredible personnel at Opioid Rehab is largely responsible for my present sobriety.\"",
//         "image": "/img/reviewer/jacob-h.png"
//     },
//     {
//         "name": "Theo",
//         "review": "I had serious concerns regarding the expense of my treatment, but the facility provided choices for financial assistance and helped me in understanding my insurance coverage. They looked after getting me the assistance I needed without putting more strain on my finances, so I never felt pressed or overburdened, and for that, I am extremely grateful.",
//         "image": "/img/reviewer/theo.png"
//     },
//     {
//         "name": "Anne Williams",
//         "review": "For me, the Opioid Rehab residential program changed everything. The setting felt so safe and trustworthy, and the staff was very caring. I went with a fresh perspective on life.",
//         "image": "/img/reviewer/anne-williams.png"
//     },
//     {
//         "name": "Kim Ayala",
//         "review": "Although I had my doubts, the virtual therapy proved to be rather effective. I never had to leave my house to get the assistance I required, and it was always there and of course, worth all the money",
//         "image": "/img/reviewer/kim-ayala.png"
//     },
//     {
//         "name": "Tisha",
//         "review": "Opioid Rehab's outpatient treatment program was just what I needed. The individualized treatment plan and flexible schedule made it possible for me to continue working while getting the help and therapy I needed to stay clean. The staff is supportive, and I never felt like I was struggling on my own. I wholeheartedly endorse this program to anybody in need of continuing assistance with their rehabilitation. These guys are wonderful.",
//         "image": "/img/reviewer/tisha.png"
//     },
//     {
//         "name": "Sam Mathew",
//         "review": "I had made many attempts to stop using opioids before I found Opioid Rehab, but the withdrawal symptoms were always too severe to handle. For me, the Medication-Assisted Treatment program here was a game-changer. There was a balance that the medicine and therapy together offered that I had never known. I'm now beginning to feel like myself again after six months. I'm very grateful to them for saving my life.",
//         "image": "/img/reviewer/sam-mathew.png"
//     },
//     {
//         "name": "J.D. Brown",
//         "review": "The outpatient program was a fantastic fit for me. I managed to balance my career and family while receiving the necessary support. Besides, the staff and experts are amazing with counseling. Great service.",
//         "image": "/img/reviewer/j-d-brown.png"
//     },
//     {
//         "name": "Amanda Bailey",
//         "review": "I was concerned about the price, but Opioid Rehab guided me through every step of the process. They ensured I could receive the assistance I required without having to worry about money. This was such a huge relief for me and my family. Thanks a lot team.",
//         "image": "/img/reviewer/amanda-bailey.png"
//     },
//     {
//         "name": "Danny Choi",
//         "review": "I'm a recovering addict. Additionally, I have a history of frequent relapses, which has resulted in two admissions to Opioid Rehab!\nThis place didn't judge me; instead, it took care of me and accepted me twice. It was incredibly motivating, and I've made a lifelong commitment to be sober!\n",
//         "image": "/img/reviewer/danny-choi.png"
//     },
//     {
//         "name": "Ryan Lewis",
//         "review": "Opioid Rehab is the best rehab I've done; nothing else comes close. They made a huge difference in my life, and I will always be appreciative.",
//         "image": "/img/reviewer/ryan-lewis.png"
//     },
//     {
//         "name": "Lizzy Green",
//         "review": "The Opioid team was outstanding. They provided me with the means to maintain my sobriety and improve my life. I am really grateful to the center. They gave me the encouragement and direction I required to ultimately overcome my addiction.",
//         "image": "/img/reviewer/lizzy-green.png"
//     },
//     {
//         "name": "Mat Davis G",
//         "review": "Opioid Rehab's outpatient treatment was the ideal option for me. I needed a program that would enable me to continue working while receiving the much-needed treatment because I had been battling addiction for years. The outpatient program surpassed my expectations. The counselors were helpful and ensured I was always cared for. The therapy sessions were really effective and provided me with fresh perspectives on my actions and mental processes.\nI've been clean for over a year, and I couldn't be happier or in better health right now. I am very grateful to Opioid Rehab for saving my life.",
//         "image": "/img/reviewer/mat-davis-g.png"
//     },
//     {
//         "name": "Ava Locke",
//         "review": "The residential program at Opioid Rehab changed my life. I felt stronger and more prepared to move on. My time at this facility equipped me with the knowledge and skills I needed to start over. I'm extremely grateful.",
//         "image": "/img/reviewer/ava-locke.png"
//     },
//     {
//         "name": "Sophie Martinez",
//         "review": "Opioid Rehab's outpatient program was ideal for my requirements. I managed my everyday life while being sober. I thought it would be a challenge with 2 kids to take care of, but this center made it all so easy.\nAs a mom, I need to take care of my physical and mental health from now on.",
//         "image": "/img/reviewer/sophie-martinez.png"
//     },
//     {
//         "name": "Lucas G",
//         "review": "Opioid Rehab's medication-assisted treatment was a lifesaver. I'm now able to manage my recovery and am no longer addicted. Medication and counseling together helped me stay on course. I'm really thankful.",
//         "image": "/img/reviewer/lucas-g.png"
//     },
//     {
//         "name": "Otis",
//         "review": "My life was transformed by the care I got at the Opioid Center. Their commitment to my recovery is greatly appreciated.\nTo be honest, receiving treatment here made me feel really secure and relieved after facing so much criticism from society. I attribute this to the extremely skilled personnel and their methods!\nThis was an amazing experience.",
//         "image": "/img/reviewer/otis.png"
//     },
//     {
//         "name": "Mark Andrew",
//         "review": "I was concerned about paying for treatment, but Opioid Rehab's financial support made it feasible. I'm really appreciative of their assistance in obtaining me the treatment I required.",
//         "image": "/img/reviewer/mark-andrew.png"
//     },
//     {
//         "name": "Sarah Khan",
//         "review": "When I initially started opioid rehab, I wasn't sure if outpatient care would be sufficient for me. I was scared that I wouldn't be able to remain on track if I wasn't in a residential program because I had been battling addiction for years. However, I was mistaken. I needed the outpatient program perfectly. I was able to continue working and taking care of my family while getting the help and treatment I required thanks to the flexible schedule. \nI am now celebrating a year sober, and I am so grateful to the Opioid Rehab team for giving me the resources and encouragement I needed to get here. I am a living example of the efficacy of outpatient therapy, and I am incredibly thankful.",
//         "image": "/img/reviewer/sarah-khan.png"
//     },
//     {
//         "name": "Lisa P",
//         "review": "I'm leading a clean and well life because of MAT at Opioid Rehab.\" The attention I got was excellent. Me and my partner are extremely happy.",
//         "image": "/img/reviewer/lisa-p.png"
//     },
//     {
//         "name": "Ruth Williams",
//         "review": "Due to the severity of my symptoms and my deteriorating mental state, I wasn't sure if outpatient therapy would be effective for me, but Opioid Rehab showed it could. I'm happier and healthier because of their amazing staff.",
//         "image": "/img/reviewer/ruth-williams.png"
//     }
// ];

const ClientsSaysAboutUs = () => {
    const [showOlder, setShowOlder] = useState(false);

    const LazyImage = (imageProps) => {
        const [shouldLoad, setShouldLoad] = useState(false);
        const placeholderRef = useRef(null);

        useEffect(() => {
            if (!shouldLoad && placeholderRef.current) {
                const observer = new IntersectionObserver(([{ intersectionRatio }]) => {
                    if (intersectionRatio > 0) {
                        setShouldLoad(true);
                    }
                });
                observer.observe(placeholderRef.current);
                return () => observer.disconnect();
            }
        }, [shouldLoad, placeholderRef]);

        return (shouldLoad
            ? <img {...imageProps} alt="" />
            : <div className="img-placeholder w-16 h-16 border-2 border-white rounded-lg mr-6" ref={placeholderRef} style={{ backgroundColor: "#f2f4f7" }} />
        );
    };

    return (
        <>
            {/* start::client  */}
            <section className="lg:py-20 border-b border-gray-200 dark:border-foreground dark:bg-background">
                <div className="container mx-auto lg:p-0 p-5">
                    {/* <div className="flex justify-between items-center flex-wrap mb-12">
                        <div className="flex-1">
                            <h2 className="text-2xl lg:text-3xl font-medium capitalize dark:text-gray-100 mb-2 aos-init" data-aos="fade-up" data-aos-duration={800}>
                                Clients Says About Us
                            </h2>
                            <p className="text-sm lg:text-base text-gray-500 dark:text-gray-400 font-normal aos-init" data-aos="fade-up" data-aos-duration={1000}>
                                A selection of listing verified for quality
                            </p>
                        </div>
                        <div className="relative w-[100px] flex-no-shrink aos-init" data-aos="fade-up" data-aos-duration={800}>
                            <div className="swiper-button-prev after:hidden swiper-button-disabled" tabIndex={0} role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-cd31810942f34d10c7" aria-disabled="true">
                                <button type="button" className="text-gray-400 hover:border-none bg-white shadow-small-content-1 hover:bg-white hover:text-blue-500 rounded-full p-3" aria-label="Previous slide">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                    </svg>
                                </button>
                            </div>
                            <div className="swiper-button-next after:hidden" tabIndex={0} role="button" aria-label="Next slide" aria-controls="swiper-wrapper-cd31810942f34d10c7" aria-disabled="false">
                                <button type="button" className="text-gray-400 bg-white shadow-small-content-1 hover:border-none hover:bg-white hover:text-blue-500 rounded-full p-3" aria-label="Next slide">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div> */}

                    <div className="flex justify-between items-center flex-wrap mb-12">
                        <div className="flex flex-col space-y-2">
                            <h2 className="text-2xl lg:text-3xl font-medium capitalize dark:text-gray-100 mb-2 aos-init" data-aos="fade-up" data-aos-duration={800}>
                                Clients Says About Us
                            </h2>
                            <p className="text-sm lg:text-base text-gray-500 dark:text-gray-400 font-normal aos-init" data-aos="fade-up" data-aos-duration={1000}>
                                A selection of listing verified for quality
                            </p>
                        </div>

                        <div className="relative w-[100px] flex space-x-4 aos-init" data-aos="fade-up" data-aos-duration={800}>
                            <div className="review-swiper-button-prev after:hidden swiper-button-disabled" tabIndex={0} role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-cd31810942f34d10c7" aria-disabled="true">
                                <button type="button" className="text-gray-400 hover:border-none bg-white shadow-small-content-1 hover:bg-white hover:text-blue-500 rounded-full p-3" aria-label="Previous slide">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                    </svg>
                                </button>
                            </div>
                            <div className="review-swiper-button-next after:hidden" tabIndex={0} role="button" aria-label="Next slide" aria-controls="swiper-wrapper-cd31810942f34d10c7" aria-disabled="false">
                                <button type="button" className="text-gray-400 bg-white shadow-small-content-1 hover:border-none hover:bg-white hover:text-blue-500 rounded-full p-3" aria-label="Next slide">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Slider main container */}
                    <div className="relative">
                        <div className="swiper swiper-center-zoom swiper-initialized swiper-horizontal swiper-pointer-events">
                            <div className="swiper-wrapper relative h-full" aria-live="polite" style={{ transform: 'translate3d(-1223px, 0px, 0px)', transitionDuration: '0ms' }}>
                                {
                                    reviews.length > 0 && reviews.map((r, index) => (
                                        <div className="swiper-slide aos-init" data-aos="fade-up" data-aos-duration={800} key={index}>
                                            <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                                <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                                <div className="flex items-center mb-2">
                                                    <div className='w-16 h-16'>
                                                        <img className="border-2 border-white rounded-lg mr-6" src={r.image} alt="" />
                                                        {/* <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/10.webp`} /> */}
                                                    </div>
                                                    <div>
                                                        <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                            {r.name}
                                                        </h3>
                                                        {/* <p className="text-sm text-gray-500 dark:text-gray-400">
                                                Australia
                                            </p> */}
                                                    </div>
                                                </div>
                                                <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]" style={{ minHeight: "358px" }}>
                                                    {r.review}
                                                </p>
                                                <div className="flex justify-end mt-5">
                                                    <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    {showOlder && <div className="relative">
                        <div className="swiper swiper-center-zoom swiper-initialized swiper-horizontal swiper-pointer-events">
                            {/* Additional required wrapper */}
                            <div className="swiper-wrapper relative h-full" id="swiper-wrapper-cd31810942f34d10c7" aria-live="polite" style={{ transform: 'translate3d(-1223px, 0px, 0px)', transitionDuration: '0ms' }}>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="1 / 12" data-swiper-slide-index={0} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/10.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/10.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Gage Paquette
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    Australia
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Nation yet I century way of the into he gone day time. And
                                            installer. To pity the left didn't doubting as for
                                            recommendation up omens, the place were coffee shut for the on
                                            anyone but it noise not has eye.
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="2 / 12" data-swiper-slide-index={1} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/10.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/10.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Gage Paquette
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    Australia
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Nation yet I century way of the into he gone day time. And
                                            installer. To pity the left didn't doubting as for
                                            recommendation up omens, the place were coffee shut for the on
                                            anyone but it noise not has eye.
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="3 / 12" data-swiper-slide-index={2} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/10.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/10.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Gage Paquette
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    Australia
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Nation yet I century way of the into he gone day time. And
                                            installer. To pity the left didn't doubting as for
                                            recommendation up omens, the place were coffee shut for the on
                                            anyone but it noise not has eye.
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-prev aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="4 / 12" data-swiper-slide-index={3} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/10.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/10.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Gage Paquette
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    Australia
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Nation yet I century way of the into he gone day time. And
                                            installer. To pity the left didn't doubting as for
                                            recommendation up omens, the place were coffee shut for the on
                                            anyone but it noise not has eye.
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                {/* Slides */}
                                <div className="swiper-slide swiper-slide-active aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="5 / 12" data-swiper-slide-index={4} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/56.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/56.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Sherry Freeman
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    USA
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur quo perspiciatis deleniti debitis aspernatur eos voluptatibus veritatis, labore excepturi similique nisi quod repudiandae aliquid laborum fuga quos pariatur delectus quae
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-next aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="6 / 12" data-swiper-slide-index={5} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/61.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/61.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Joy Chambers
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    USA
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur quo perspiciatis deleniti debitis aspernatur eos voluptatibus veritatis, labore excepturi similique nisi quod repudiandae aliquid laborum fuga quos pariatur delectus quae
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="7 / 12" data-swiper-slide-index={6} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/59.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/59.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Cherly Morgan
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    USA
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate-prev aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="8 / 12" data-swiper-slide-index={7} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/38.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/59.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Minnie Gibson
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    USA
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur quo perspiciatis deleniti debitis aspernatur eos voluptatibus veritatis, labore excepturi similique nisi quod repudiandae aliquid laborum fuga quos pariatur delectus quae.
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="9 / 12" data-swiper-slide-index={8} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/10.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/10.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Gage Paquette
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    Australia
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Nation yet I century way of the into he gone day time. And
                                            installer. To pity the left didn't doubting as for
                                            recommendation up omens, the place were coffee shut for the on
                                            anyone but it noise not has eye.
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="10 / 12" data-swiper-slide-index={9} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/10.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/10.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Gage Paquette
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    Australia
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Nation yet I century way of the into he gone day time. And
                                            installer. To pity the left didn't doubting as for
                                            recommendation up omens, the place were coffee shut for the on
                                            anyone but it noise not has eye.
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="11 / 12" data-swiper-slide-index={10} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/10.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/10.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Gage Paquette
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    Australia
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Nation yet I century way of the into he gone day time. And
                                            installer. To pity the left didn't doubting as for
                                            recommendation up omens, the place were coffee shut for the on
                                            anyone but it noise not has eye.
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide swiper-slide-duplicate aos-init" data-aos="fade-up" data-aos-duration={800} role="group" aria-label="12 / 12" data-swiper-slide-index={11} style={{ width: '398px', marginRight: '10px' }}>
                                    <div className="bg-gray-50 dark:bg-foreground rounded-lg p-5 relative overflow-hidden">
                                        <div className="w-20 h-20 rounded-full dark:bg-background bg-gray-100 absolute -right-4 -top-4" />
                                        <div className="flex items-center mb-9">
                                            {/* <img className="w-16 h-16 border-2 border-white rounded-lg mr-6" src="/img/faces/10.webp" alt="" /> */}
                                            <LazyImage className={'w-16 h-16 border-2 border-white rounded-lg mr-6'} src={`/img/faces/10.webp`} />
                                            <div>
                                                <h3 className="text-sm text-gray-700 dark:text-gray-100 font-medium mb-1">
                                                    Gage Paquette
                                                </h3>
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                    Australia
                                                </p>
                                            </div>
                                        </div>
                                        <p className="text-sm text-gray-500 dark:text-gray-500 leading-[24px]">
                                            Nation yet I century way of the into he gone day time. And
                                            installer. To pity the left didn't doubting as for
                                            recommendation up omens, the place were coffee shut for the on
                                            anyone but it noise not has eye.
                                        </p>
                                        <div className="flex justify-end mt-5">
                                            <svg className="text-gray-100 dark:text-background" width={54} height={41} viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M29.992 10.432C29.992 7.28067 31.0243 4.78134 33.089 2.934C35.1537 0.977999 37.7617 0 40.913 0C44.7163 0 47.8133 1.24967 50.204 3.749C52.7033 6.24833 53.953 9.83433 53.953 14.507C53.953 19.5057 52.486 24.45 49.552 29.34C46.7267 34.1213 42.706 37.9247 37.49 40.75L33.741 35.045C36.5663 33.089 38.794 30.9157 40.424 28.525C42.1627 26.1343 43.3037 23.3633 43.847 20.212C42.869 20.6467 41.728 20.864 40.424 20.864C37.3813 20.864 34.882 19.886 32.926 17.93C30.97 15.974 29.992 13.4747 29.992 10.432ZM0 10.432C0 7.28067 1.03233 4.78134 3.097 2.934C5.16167 0.977999 7.76967 0 10.921 0C14.7243 0 17.8213 1.24967 20.212 3.749C22.7113 6.24833 23.961 9.83433 23.961 14.507C23.961 19.5057 22.494 24.45 19.56 29.34C16.7347 34.1213 12.714 37.9247 7.498 40.75L3.749 35.045C6.57433 33.089 8.802 30.9157 10.432 28.525C12.1707 26.1343 13.3117 23.3633 13.855 20.212C12.877 20.6467 11.736 20.864 10.432 20.864C7.38933 20.864 4.89 19.886 2.934 17.93C0.978 15.974 0 13.4747 0 10.432Z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* If we need pagination */}
                            <div className="swiper-pagination"></div>
                            {/* If we need navigation buttons */}
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                            <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                        </div>
                    </div>}
                    <div className="relative w-24 mx-auto mt-10"></div>
                </div>
            </section>
            {/* end::client  */}
        </>
    )
}

export default ClientsSaysAboutUs